import { DataGrid, DataGridHead, DataGridRow, DataGridHeader, DataGridBody, DataGridCell } from "@twilio-paste/data-grid";
import { useUIDSeed } from "@twilio-paste/core/uid-library";
import { FC, useState } from "react";
import { CheckboxGroup } from "@twilio-paste/checkbox";
import { CheckboxCell } from "../Checkbox/CheckboxCell";
import { TableHeaderData, TableBodyData } from "../../constants";

export const DocumentsGrid: FC = () => {
	const seed = useUIDSeed();
	// Array of length 10 rows, all unchecked
	const [checkedItems, setCheckedItems] = useState(
		[...new Array(10)].map(() => false)
	);

	const allChecked = checkedItems.every(Boolean);
	const indeterminate = checkedItems.some(Boolean) && !allChecked;

	/* eslint-disable react/no-array-index-key */
	return (
		<CheckboxGroup name="items" legend="Select document">
			<DataGrid aria-label="documents-grid">
				<DataGridHead>
					<DataGridRow>
						<DataGridHeader width="55px">
							<CheckboxCell
								onClick={(checked: boolean) => {
									const newCheckedItems = checkedItems.map(() => checked);
									setCheckedItems(newCheckedItems);
								}}
								id={seed("select-all")}
								checked={allChecked}
								indeterminate={indeterminate}
								label="Select all"
							/>
						</DataGridHeader>
						<DataGridHeader>{TableHeaderData[0]}</DataGridHeader>
						<DataGridHeader>{TableHeaderData[1]}</DataGridHeader>
						<DataGridHeader>{TableHeaderData[2]}</DataGridHeader>
						<DataGridHeader>{TableHeaderData[3]}</DataGridHeader>
						<DataGridHeader>{TableHeaderData[4]}</DataGridHeader>
					</DataGridRow>
				</DataGridHead>
				<DataGridBody>
					{TableBodyData.map((row, rowIndex) => (
						<DataGridRow
							key={`row-${rowIndex}`}
							selected={checkedItems[rowIndex]}
						>
							<DataGridCell>
								<CheckboxCell
									onClick={(checked: boolean) => {
										const newCheckedItems = [...checkedItems];
										newCheckedItems[rowIndex] = checked;
										setCheckedItems(newCheckedItems);
									}}
									id={seed(`row-${rowIndex}-checkbox`)}
									checked={checkedItems[rowIndex]}
									label={`Select row ${rowIndex}`}
								/>
							</DataGridCell>
							{row.map((col, colIndex) => (
								<DataGridCell key={`col-${colIndex}`}>{col}</DataGridCell>
							))}
						</DataGridRow>
					))}
				</DataGridBody>
			</DataGrid>
		</CheckboxGroup>
	);
	/* eslint-enable react/no-array-index-key */
};