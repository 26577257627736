import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../interface";
import { CCPContextType } from "../../types/ciptex-sdk";
import {
	Campaign,
	CCPClient,
	ClientCore,
	CampaignNew,
	Languages,
	SLA,
	CampaignConfigNew,
	ClientNew,
	ComplaintNew,
	DocumentNew,
	EndClientNew,
	OutsourcerNew,
	UserNew,
	Campaigns,
	CampaignConfigs,
	Complaints,
	Documents,
	EndClientCore,
	OutsourcerCore,
	Users,
	DocumentView,
	PhoneNumbers,
	PhoneNumberNew,
	CampaignConfig,
	Client,
	EndClient,
	Outsourcer,
	ValidationResponse,
	UserAuth,
	SetPasswordBody,
	User,
	ClientConfig,
	VerifySend,
	VerifySendResult,
	VerifyCheck,
	VerifyCheckResult,
	Complaint
} from "@ciptex/ccp";

export const CCPContext = createContext<CCPContextType>(null!);

export const CCPProvider: FC<ReactElementProps> = ({
	children
}: ReactElementProps) => {
	const [ccpClient, setCCPClient] = useState<CCPClient>();
	const [providerReady, setProviderReady] = useState<boolean>(false);

	const connect = useCallback((token: string) => {
		try {
			const client = new CCPClient({ TOKEN: token });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.ccpClient = client;
			setCCPClient(client);
			setProviderReady(true);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	const listCampaign = useCallback(async (): Promise<Campaigns> => {
		const campaigns = await ccpClient?.campaign.list();
		if (campaigns) {
			return campaigns;
		} else {
			throw new Error("No Campaigns");
		}
	}, [ccpClient]);

	const listClients = useCallback(async (): Promise<ClientCore> => {
		const clients = await ccpClient?.client.list();
		if (clients) {
			return clients;
		} else {
			throw new Error("No Clients");
		}
	}, [ccpClient]);

	const listCampaignCampaignConfigs = useCallback(async (campaignId: number): Promise<CampaignConfigs> => {
		const campaignConfigs  = await ccpClient?.campaigncampaignconfig.fetch(campaignId);
		if (campaignConfigs) {
			return campaignConfigs;
		}
		else {
			throw new Error("No Campaign Configurations for this Campaign");
		}
	}, [ccpClient]);

	const listCampaignConfig = useCallback(async (): Promise<CampaignConfigs> => {
		const campaignConfig = await ccpClient?.campaignconfig.list();
		if (campaignConfig) {
			return campaignConfig;
		} else {
			throw new Error("No Campaign Config");
		}
	}, [ccpClient]);

	const listComplaints = useCallback(async (): Promise<Complaints> => {
		const complaints = await ccpClient?.complaint.list();
		if (complaints) {
			return complaints;
		} else {
			throw new Error("No Complaints");
		}
	}, [ccpClient]);

	const listDocuments = useCallback(async (): Promise<Documents> => {
		const documents = await ccpClient?.document.list();
		if (documents) {
			return documents;
		} else {
			throw new Error("No Documents");
		}
	}, [ccpClient]);

	const listEndClients = useCallback(async (): Promise<EndClientCore> => {
		const endClients = await ccpClient?.endclient.list();
		if (endClients) {
			return endClients;
		} else {
			throw new Error("No End Clients");
		}
	}, [ccpClient]);

	const listLanguages = useCallback(async (): Promise<Languages> => {
		const languages = await ccpClient?.language.list();
		if (languages) {
			return languages;
		} else {
			throw new Error("No Languages");
		}
	}, [ccpClient]);

	const listOutsourcers = useCallback(async (): Promise<OutsourcerCore> => {
		const outsourcers = await ccpClient?.outsourcer.list();
		if (outsourcers) {
			return outsourcers;
		} else {
			throw new Error("No Outsourcers");
		}
	}, [ccpClient]);

	const listUsers = useCallback(async (): Promise<Users> => {
		const users = await ccpClient?.user.list();
		if (users) {
			return users;
		} else {
			throw new Error("No Users");
		}
	}, [ccpClient]);

	const listUsersEmailFilter = useCallback(
		async (email: string): Promise<Users> => {
			const users = await ccpClient?.user.list(email);
			if (users) {
				return users;
			} else {
				throw new Error("Cannot get user by email");
			}
		},
		[ccpClient]
	);

	const listSlas = useCallback(async (): Promise<SLA> => {
		const slas = await ccpClient?.sla.list();
		if (slas) {
			return slas;
		} else {
			throw new Error("No SLAs");
		}
	}, [ccpClient]);

	const listPhoneNumbers = useCallback(async (): Promise<PhoneNumbers> => {
		const phoneNumbers = await ccpClient?.phonenumber.list();
		if (phoneNumbers) {
			return phoneNumbers;
		} else {
			throw new Error("No Phone Numbers");
		}
	}, [ccpClient]);

	const createCampaign = useCallback(
		async (requestBody: CampaignNew): Promise<CampaignNew> => {
			const campaign = await ccpClient?.campaign.create(requestBody);
			if (campaign) {
				return campaign;
			} else {
				throw new Error("Cannot create new campaign");
			}
		},
		[ccpClient]
	);

	const createCampaignConfig = useCallback(
		async (requestBody: CampaignConfigNew): Promise<CampaignConfigNew> => {
			const campaignConfig = await ccpClient?.campaignconfig.create(
				requestBody
			);
			if (campaignConfig) {
				return campaignConfig;
			} else {
				throw new Error("Cannot create new campaign config");
			}
		},
		[ccpClient]
	);

	const createClient = useCallback(
		async (requestBody: ClientNew): Promise<ClientNew> => {
			const client = await ccpClient?.client.create(requestBody);
			if (client) {
				return client;
			} else {
				throw new Error("Cannot create new client");
			}
		},
		[ccpClient]
	);

	const createComplaint = useCallback(
		async (requestBody: ComplaintNew): Promise<ComplaintNew> => {
			const complaint = await ccpClient?.complaint.create(requestBody);
			if (complaint) {
				return complaint;
			} else {
				throw new Error("Cannot create new complaint");
			}
		},
		[ccpClient]
	);

	const createDocument = useCallback(
		async (requestBody: DocumentNew): Promise<DocumentNew> => {
			const document = await ccpClient?.document.create(requestBody);
			if (document) {
				return document;
			} else {
				throw new Error("Cannot create new document");
			}
		},
		[ccpClient]
	);

	const createDocumentViewRecord = useCallback(
		async (documentId: number, requestBody: any): Promise<DocumentView> => {
			const documentView = await ccpClient?.documentview.create(
				documentId,
				requestBody
			);
			if (documentView) {
				return documentView;
			} else {
				throw new Error("Cannot create new document view");
			}
		},
		[ccpClient]
	);

	const createEndClient = useCallback(
		async (requestBody: EndClientNew): Promise<EndClientNew> => {
			const endClient = await ccpClient?.endclient.create(requestBody);
			if (endClient) {
				return endClient;
			} else {
				throw new Error("Cannot create new end client");
			}
		},
		[ccpClient]
	);

	const createOutsourcer = useCallback(
		async (requestBody: OutsourcerNew): Promise<OutsourcerNew> => {
			const outsourcer = await ccpClient?.outsourcer.create(requestBody);
			if (outsourcer) {
				return outsourcer;
			} else {
				throw new Error("Cannot create new outsourcer");
			}
		},
		[ccpClient]
	);

	const createPhoneNumber = useCallback(
		async (requestBody: PhoneNumberNew): Promise<PhoneNumberNew> => {
			const phonenumber = await ccpClient?.phonenumber.create(requestBody);
			if (phonenumber) {
				return phonenumber;
			} else {
				throw new Error("Cannot create new phone number");
			}
		},
		[ccpClient]
	);

	const createUser = useCallback(
		async (requestBody: UserNew): Promise<UserNew> => {
			const user = await ccpClient?.user.create(requestBody);
			if (user) {
				return user;
			} else {
				throw new Error("Cannot create new user");
			}
		},
		[ccpClient]
	);

	const deleteCampaign = async (campaignId: number): Promise<void> => {
		await ccpClient?.campaign.deleteCampaign(campaignId);
	};

	const deleteCampaignConfig = async (
		campaignConfigId: number
	): Promise<void> => {
		await ccpClient?.campaignconfig.deleteCampaignConfig(campaignConfigId);
	};

	const deleteClient = async (clientId: number): Promise<void> => {
		await ccpClient?.client.deleteClient(clientId);
	};

	const deleteComplaint = async (complaintId: number): Promise<void> => {
		await ccpClient?.complaint.deleteComplaint(complaintId);
	};

	const deleteDocument = async (documentId: number): Promise<void> => {
		await ccpClient?.document.deleteDocument(documentId);
	};

	const deleteEndClient = async (endClientId: number): Promise<void> => {
		await ccpClient?.endclient.deleteEndClient(endClientId);
	};

	const deleteOutsourcer = async (outsourcerId: number): Promise<void> => {
		await ccpClient?.outsourcer.deleteOutsourcer(outsourcerId);
	};

	const deletePhoneNumber = async (phoneNumberId: number): Promise<void> => {
		await ccpClient?.phonenumber.deletephoneNumber(phoneNumberId);
	};

	const deleteUser = async (userId: number): Promise<void> => {
		await ccpClient?.user.deleteUser(userId);
	};

	const updateDocument = useCallback(
		async (documentId: number, requestBody: DocumentNew): Promise<void> => {
			await ccpClient?.document.update(documentId, requestBody);
		},
		[ccpClient]
	);

	const updateCampaign = useCallback(
		async (campaignId: number, requestBody: Campaign): Promise<void> => {
			await ccpClient?.campaign.update(campaignId, requestBody);
		},
		[ccpClient]
	);

	const updateCampaignConfig = useCallback(
		async (
			campaignConfigId: number,
			requestBody: CampaignConfig
		): Promise<void> => {
			await ccpClient?.campaignconfig.update(campaignConfigId, requestBody);
		},
		[ccpClient]
	);

	const updateClient = useCallback(
		async (clientId: number, requestBody: Client): Promise<void> => {
			await ccpClient?.client.update(clientId, requestBody);
		},
		[ccpClient]
	);

	const updateEndClient = useCallback(
		async (endClientId: number, requestBody: EndClient): Promise<void> => {
			await ccpClient?.endclient.update(endClientId, requestBody);
		},
		[ccpClient]
	);

	const updateOutsourcer = useCallback(
		async (outsourcerId: number, requestBody: Outsourcer): Promise<void> => {
			await ccpClient?.outsourcer.update(outsourcerId, requestBody);
		},
		[ccpClient]
	);

	const updateUser = useCallback(
		async (userId: number, requestBody: User): Promise<void> => {
			await ccpClient?.user.update(userId, requestBody);
		},
		[ccpClient]
	);

	const updateComplaint = useCallback(
		async (complaintId: number, requestBody: Complaint): Promise<void> => {
			await ccpClient?.complaint.update(complaintId, requestBody);
		},
		[ccpClient]
	);

	const getCampaign = useCallback(
		async (campaignId: number): Promise<Campaign> => {
			const campaign = await ccpClient?.campaign.fetch(campaignId);
			if (campaign) {
				return campaign;
			} else {
				throw new Error("Cannot get campaign");
			}
		},
		[ccpClient]
	);

	const getClient = useCallback(
		async (clientId: number): Promise<Client> => {
			const client = await ccpClient?.client.fetch(clientId);
			if (client) {
				return client;
			} else {
				throw new Error("Cannot get client");
			}
		},
		[ccpClient]
	);

	const getEndClient = useCallback(
		async (endClientId: number): Promise<EndClient> => {
			const endClient = await ccpClient?.endclient.fetch(endClientId);
			if (endClient) {
				return endClient;
			} else {
				throw new Error("Cannot get End Client");
			}
		},
		[ccpClient]
	);

	const getEndClientSla = useCallback(
		async (endClientId: number): Promise<SLA> => {
			const SLA = await ccpClient?.endclientsla.fetch(endClientId);
			if (SLA) {
				return SLA;
			} else {
				throw new Error("Cannot get End Client SLA");
			}
		},
		[ccpClient]
	);

	const getCampaignSla = useCallback(
		async (campaignId: number): Promise<SLA> => {
			const SLA = await ccpClient?.campaignsla.fetch(campaignId);
			if (SLA) {
				return SLA;
			} else {
				throw new Error("Cannot get Campaign SLA");
			}
		},
		[ccpClient]
	);

	const getOutsourcer = useCallback(
		async (outsourcerId: number): Promise<Outsourcer> => {
			const outsourcer = await ccpClient?.outsourcer.fetch(outsourcerId);
			if (outsourcer) {
				return outsourcer;
			} else {
				throw new Error("Cannot get Outsourcer");
			}
		},
		[ccpClient]
	);

	const getCampaignConfig = useCallback(
		async (campaignConfigId: number): Promise<CampaignConfig> => {
			const campaignConfig = await ccpClient?.campaignconfig.fetch(
				campaignConfigId
			);
			if (campaignConfig) {
				return campaignConfig;
			} else {
				throw new Error("Cannot get Campaign Config Data");
			}
		},
		[ccpClient]
	);

	const getUser = useCallback(
		async (userId: number): Promise<User> => {
			const user = await ccpClient?.user.fetch(userId);
			if (user) {
				return user;
			} else {
				throw new Error("Cannot get User Data");
			}
		},
		[ccpClient]
	);

	const getClientConfig = useCallback(
		async (clientId: number): Promise<ClientConfig> => {
			const clientConfig = await ccpClient?.clientconfig.fetch(clientId);
			if (clientConfig) {
				return clientConfig;
			} else {
				throw new Error("Cannot get clientconfig");
			}
		},
		[ccpClient]
	);

	const validateUserPassword = useCallback(
		async (requestBody: UserAuth): Promise<ValidationResponse> => {
			const validationResponse = await ccpClient?.validation.validatePassword(
				requestBody
			);
			if (validationResponse) {
				return validationResponse;
			} else {
				throw new Error("Cannot validate password");
			}}, [ccpClient]);


	const sendVerification = useCallback(
		async (requestBody: VerifySend): Promise<VerifySendResult> => {
			const verify = await ccpClient?.identitysend.sendVerifySms(requestBody);
			if (verify) {
				return verify;
			} else {
				throw new Error("Cannot send code");
			}
		},
		[ccpClient]
	);

	const setUserPassword = useCallback(
		async (requestBody: SetPasswordBody): Promise<void> => {
			await ccpClient?.password.setPassword(
				requestBody
			);
		}, [ccpClient]);

	const checkVerification = useCallback(
		async (requestBody: VerifyCheck): Promise<VerifyCheckResult> => {
			const verify = await ccpClient?.identitycheck.checkVerify(requestBody);
			if (verify) {
				return verify;
			} else {
				throw new Error("Cannot check verification code");
			}
		},
		[ccpClient]
	);

	const listCampaignConfigDocuments = useCallback(
		async (campaignConfigId: number): Promise<Documents> => {
			const documents = await ccpClient?.campaignconfigdocument.fetch(campaignConfigId);
			if (documents) {
				return documents;
			} else {
				throw new Error("No documents for this configuration");
			}
		},

		[ccpClient]

	);

	const getDocumentViews = useCallback(
		async (documentId: number): Promise<DocumentView> => {
			const documentViews = await ccpClient?.documentview.fetch(documentId);
			if (documentViews) {
				return documentViews;
			} else {
				throw new Error("Cannot get document views");
			}
		},
		[ccpClient]
	);

	return (
		<CCPContext.Provider
			value={{
				providerReady,
				connect,
				listPhoneNumbers,
				listCampaign,
				listClients,
				listCampaignConfig,
				listComplaints,
				listDocuments,
				listEndClients,
				listLanguages,
				listOutsourcers,
				listUsers,
				listSlas,
				createCampaign,
				createCampaignConfig,
				createClient,
				createComplaint,
				createDocument,
				createEndClient,
				createOutsourcer,
				createDocumentViewRecord,
				createUser,
				createPhoneNumber,
				deleteCampaign,
				deleteCampaignConfig,
				deleteClient,
				deleteComplaint,
				deleteDocument,
				deleteEndClient,
				deleteOutsourcer,
				deletePhoneNumber,
				deleteUser,
				updateCampaign,
				updateCampaignConfig,
				updateClient,
				updateEndClient,
				updateOutsourcer,
				updateUser,
				getCampaign,
				getClient,
				getEndClient,
				getEndClientSla,
				getOutsourcer,
				getCampaignConfig,
				getUser,
				getClientConfig,
				listUsersEmailFilter,
				validateUserPassword,
				setUserPassword,
				getCampaignSla,
				sendVerification,
				checkVerification,
				updateComplaint,
				listCampaignConfigDocuments,
				listCampaignCampaignConfigs,
				getDocumentViews,
				updateDocument
			}}
		>
			{children}
		</CCPContext.Provider>
	);
};
