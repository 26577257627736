import { FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/box";
import { useNavigate } from "react-router-dom";
import { Button } from "@twilio-paste/button";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { CampaignConfigs } from "@ciptex/ccp";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { FileIcon } from "@twilio-paste/icons/esm/FileIcon";

export const CampaignDocuments: FC = () => {
	const navigate = useNavigate();
	const { listCampaignConfig } = useCCPContext();

	const { toaster } = useToasterContext();

	const [campaignConfigs, setCampaignConfigs] = useState<CampaignConfigs>();
	const [loaded, setLoaded] = useState<boolean>();

	useEffect(() => {
		(async () => {
			try {
				const data: CampaignConfigs = await listCampaignConfig();

				let d = [];

				for( const cc of data)
				{
					let langs = '';
					for (const l of cc.languages ?? [])
					{
						if (langs.length > 0)
							langs += ', ' + l.language;
						else
							langs = l.language ?? '';
					}

					d.push({...cc, langString : langs})
				}


				setCampaignConfigs(d);
				console.log(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);



	return (
		<Box width="100%">
			<HeaderTitleText titleText="Campaign Configs" />
			<Table aria-label="Clients table" striped>
				<THead >
					<Tr>
						<Th>Campaign Reference</Th>
						<Th>Language</Th>
						<Th textAlign="right">View Documents</Th>
					</Tr>
				</THead>
				<TBody>
					{campaignConfigs && loaded ? (
						campaignConfigs && campaignConfigs.map((cc: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td >
									{cc.reference}
								</Td>
								<Td >
									{ cc.langString}
								</Td>
								<Td >
									<Box display="flex" justifyContent="flex-end" flexDirection="row" columnGap="space60">
										<Button variant="secondary" onClick={() => navigate(`view/${cc.campaignConfigId}`)}>
											<FileIcon decorative={false} title="View configuration" /></Button>
									</Box>
								</Td>
							</Tr>
						))): <TableSkeletonLoader numberOfTd={3} numberOfTr={1} />}
				</TBody>
			</Table>
		</Box>




	);
};
