import { useContext } from "react";
import { CCPContext } from "../../components/CCPProvider/CCPProvider";
import { CCPContextType } from "../../types/ciptex-sdk";

export const useCCPContext = (): CCPContextType => {
	const context = useContext(CCPContext);
	if (!context) {
		throw new Error("useCCPContext must be used within a CCPProvider");
	}
	return context;
}