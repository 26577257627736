/* eslint @typescript-eslint/no-var-requires: "off" */

import { Box } from "@twilio-paste/box";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { FORM_BACKGROUND } from "../../constants";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useParams } from "react-router-dom";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { Button } from "@twilio-paste/core/button";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Separator, TextArea } from "@twilio-paste/core";
import { useToasterContext } from "../../hooks/useToasterContext";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";




export const NonAuthDocumentPop: FC = () => {
	const { campaignConfigId, outsourcerId, sessionId, token } = useParams();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [complaint, setComplaint] = useState<any>({});
	const [validDoc, setValidDoc] = useState<boolean>(false);
	const [docUrl, setDocUrl] = useState<string>("");
	const [doc, setDoc] = useState<any>([]);

	const { toaster } = useToasterContext();


	const validateToken = async () => {
		const result = await fetch(
			"https://ccp-smp-api.london.ciptexdemo.uk/token/validate",
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Basic ${token}`
				}
			}
		);

	};

	const fetchDocuments = async () => {
		const result = await fetch(
			`https://ccp-smp-api.london.ciptexdemo.uk/campaignconfig/${campaignConfigId}/document`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Basic ${token}`
				}
			}
		);
		const data = await result.json();
		const check : any = data;

		if(check.error_message)
		{
			console.error(check.error_message);
		}
		else{
			setDoc(data);
			setValidDoc(true);
		}

		setLoaded(true);
	};

	const getCampaignConfig = async () => {
		const result = await fetch(
			`https://ccp-smp-api.london.ciptexdemo.uk/campaignconfig/${parseInt(campaignConfigId ?? "0")}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Basic ${token}`
				}
			}
		);
		const data = await result.json();
		return data;
	};

	const createDocumentView = async (documentId : number, url_ : string) => {

		setDocUrl(url_);

		const axios = require('axios');
		const data = JSON.stringify({"documentId":documentId,"versionNumber":-1,"updatedBy":sessionId});

		const config = {
		method: 'post',
		url: `https://ccp-smp-api.london.ciptexdemo.uk/document/${documentId}/view`,
		headers: { 
			'Content-Type': 'application/json', 
			'Accept': 'application/json', 
			Authorization: `Basic ${token}`		
		},
		data : data
		};

		axios(config)
		.then(function (response : any) {
		console.log(JSON.stringify(response.data));
		})
		.catch(function (error : any) {
		console.log(error);
		toaster.push({
			message: "Could not create document view",
			variant: "error",
			dismissAfter: 4000
		});
		});

		
	};

	const createComplaint = async (newComplaint : any) => {

		const axios = require('axios');
		const data = JSON.stringify(newComplaint);

		const config = {
		method: 'post',
		url: `https://ccp-smp-api.london.ciptexdemo.uk/complaint`,
		headers: { 
			'Content-Type': 'application/json', 
			'Accept': 'application/json', 
			Authorization: `Basic ${token}`		
		},
		data : data
		};

		axios(config)
		.then(function (response : any) {
		console.log(JSON.stringify(response.data));
		toaster.push({
			message: "Complaint logged successfully",
			variant: "success",
			dismissAfter: 4000
		});
		})
		.catch(function (error : any) {
		console.log(error);
		toaster.push({
			message: "Could not create complaint",
			variant: "error",
			dismissAfter: 4000
		});
		});

		
	};


	// oncchange function for input
	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setComplaint({
			...complaint,
			[target.name]: target.value
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		(async () => {
			try {
				const cc = await getCampaignConfig();
				const newComplaint = 
					{
						...complaint, 
						['outsourcerId'] : outsourcerId,
						['campaignId'] :  cc.campaignId,
						['isSeen'] : 0
					}
				await createComplaint(
					newComplaint
				);
				setComplaint({});
				setLoaded(true);
				
			} catch (error) {
				console.error(error);
				
			}
		})();
	};

	useEffect(() => {
		validateToken();
		fetchDocuments();
		//get clientid and campaignid:
	}, [token]);

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space40"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
		>
			<Box
				display="Flex"
				justifyContent="flex-start"
				width="100%"
				marginBottom="space80"
			>
				<Button variant="primary" onClick={() => validateToken()}>
          Retry
				</Button>
			</Box>
			{loaded && validDoc && loaded  ? (
				validDoc ? (
					<>
						<Box>
							<HeaderTitleText titleText="Document Viewer" />

							<Box marginY="space60">
								<Table>
									<THead>
										<Tr>
											<Th>Document name</Th>
											<Th textAlign="right" width="size20">
                        Action
											</Th>
										</Tr>
									</THead>
									<TBody>
										{doc && (doc ?? [{'name' : 'No documents found'}]).map((item: any, index: number) => (
											<Tr key={index}>
												<Td>{item.name}</Td>
												<Td textAlign="right">
													<Button
														variant="primary"
														onClick={() => createDocumentView(item.documentId, item.url)}
													>
                            Open
													</Button>
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Box>
						</Box>

						{docUrl !== "" && (
							<>
								<iframe
									title="google-drive-document"
									src={docUrl}
									style={{
										width: "100%",
										height: "100%",
										minHeight: "500px",
										border: "none",
										overflow: "hidden"
									}}
								/>
								<Box marginTop="space60">
									<Button variant="secondary" onClick={() => setDocUrl("")}>
                    Close the document
									</Button>
								</Box>
							</>
						)}

						
					</>
				) : (
					<Box>
						<HeaderTitleText titleText="Invalid Token" />
					</Box>
				)
			) : (
				<Box>
				<SubtitleText subtitleText="No documents found"/>
				<SkeletonLoader width="100%" height="200px" />
				</Box>
			)}
			<Separator orientation="horizontal" verticalSpacing="space60" />
						<Box>
							
							<HeaderTitleText titleText="Log Complaint" />
							<Box marginY="space60">
								<form onSubmit={(e) => handleSubmit(e)}>
									<Box display="flex" flexDirection="row" columnGap="space60">
										<Box>
											<Label htmlFor="email" required>
                        Email address
											</Label>
											<Input
												aria-describedby="email_help_text"
												id="email"
												name="email"
												type="email"
												placeholder="example@twilio.com"
												onChange={handleChange}
												required
											/>
											{/* <HelpText id="email_help_text">Use your work email address.</HelpText> */}
										</Box>
										<Box>
											<Label htmlFor="phone" required>
                        Phone number
											</Label>
											<Input
												aria-describedby="phone"
												id="phone"
												name="phone"
												type="tel"
												placeholder="+447412999946"
												onChange={handleChange}
												required
											/>
											{/* <HelpText id="phone">Use your work email address.</HelpText> */}
										</Box>
									</Box>
									<Box display="flex" flexDirection="row" marginY="space60">
										<Box width="410px">
											<Label htmlFor="body" required>
                        Message (Max 2000 characters)
											</Label>
											<TextArea
												aria-describedby="message_help_text"
												id="body"
												name="body"
												onChange={handleChange}
												required
											/>
											{/* <HelpText id="message_help_text">Enter at least 120 characters</HelpText> */}
										</Box>
									</Box>
									<Box display="flex" flexDirection="row" marginY="space60">
										<Button variant="primary" type="submit">
                      Submit
										</Button>
									</Box>
								</form>
							</Box>
						</Box>
		</Box>
		
	);
};
