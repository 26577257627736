import { Box } from "@twilio-paste/core/box";
import { FC, useEffect, useState, ChangeEvent, FormEvent } from "react";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import {
	User,
	Users,
	VerifySend,
	VerifySendResult,
	VerifyCheck,
	VerifyCheckResult
} from "@ciptex/ccp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Spinner } from "@twilio-paste/core/spinner";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Input } from "@twilio-paste/core/input";
import { Button } from "@twilio-paste/core/button";
import { Flex } from "@twilio-paste/core/flex";
import { Label } from "@twilio-paste/core/label";
import { useHeight } from "../../hooks/useHeight/useHeight";

export const TwoFactor: FC = () => {
	const navigate = useNavigate();
	const { getUser, listUsersEmailFilter, sendVerification, checkVerification } =
    useCCPContext();
	const { toaster } = useToasterContext();
	const [loaded, setLoaded] = useState<boolean>();
	const [userId, setUserId] = useState<number>();
	const [user, setUser] = useState<User>();
	const { appState } = useAppState();
	const height = useHeight();

	const [verificationCode, setVerificationCode] = useState<number>();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		(async () => {
			try {
				const users: Users = await listUsersEmailFilter(
					encodeURI(appState.email)
				);
				setUser(users[0]);
				setUserId(users[0].userId);
				setLoaded(true);


				// Send verify code
				if (users[0]?.phone !== undefined) {
					const verifySendResult = await sendVerification({
						phoneNumber: users[0].phone
					});

					if (verifySendResult.verificationStatus !== "cancelled") {
						toaster.push({
							message:
                "Verification code sent to " +
                users[0].phone +
                " with a status of " +
                verifySendResult.verificationStatus,
							variant: "neutral",
							dismissAfter: 4000
						});
					} else {
						toaster.push({
							message:
                "Error sending verification code. Please contact support",
							variant: "error",
							dismissAfter: 4000
						});
					}
				} else {
					// Show error
					toaster.push({
						message: "Error sending verification code. Please contact support",
						variant: "error",
						dismissAfter: 4000
					});
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve user data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		setVerificationCode(parseInt(value));
	};

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			const verifyCheck = await checkVerification({
				phoneNumber: user?.phone,
				code: verificationCode
			});
			if (verifyCheck.verificationCheckStatus === "approved") {
				navigate(searchParams.get("redirect") || "/complaints", {
					replace: true
				});
			} else {
				toaster.push({
					message: "Verification code is incorrect, please try again",
					variant: "error",
					dismissAfter: 4000
				});
			}
		} catch (error) {
			console.error(error);
			toaster.push({
				message:
          "Error validating code. Please try again or contact support if this issue persists",
				variant: "error",
				dismissAfter: 4000
			});
		}
	};

	const resendCode = async () => {
		try {
			// Send verify code
			if (user?.phone !== undefined) {
				const verifySendResult = await sendVerification({
					phoneNumber: user.phone
				});

				if (verifySendResult.verificationStatus !== "cancelled") {
					toaster.push({
						message:
              "Verification code sent to " +
              user.phone +
              " with a status of " +
              verifySendResult.verificationStatus,
						variant: "neutral",
						dismissAfter: 4000
					});
				} else {
					toaster.push({
						message: "Error sending verification code. Please contact support",
						variant: "error",
						dismissAfter: 4000
					});
				}
			} else {
				// Show error
				toaster.push({
					message: "Error sending verification code. Please contact support",
					variant: "error",
					dismissAfter: 4000
				});
			}
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Could not retrieve user data",
				variant: "error",
				dismissAfter: 4000
			});
		}
	};

	return (
		<Box
			height={height}
			display="flex"
			flexDirection="column"
			flex={1}
			alignContent="center"
			justifyContent="center"
			alignItems="center"
			justifyItems="center"
		>
			<Box
				borderColor="colorBorder"
				borderWidth="borderWidth10"
				borderStyle="solid"
				width={["100%", "30rem", "30rem"]}
				borderRadius="borderRadius30"
				padding="space100"
			>
				<HeaderTitleText titleText="Enter Your Verification Code" />
				{user && loaded ? (
					<>
						<form onSubmit={onSubmit}>
							<Label htmlFor="code">
                Input the code sent to {user.phone} to access your account.
							</Label>
							<Box
								paddingY="space60"
								display="inline-flex"
								columnGap="space60"
								alignItems="center"
								width="max-content"
							>
								<Input
									required
									type="number"
									id="code"
									name="code"
									placeholder="Enter code...."
									onChange={onChange}
								/>
								<Button variant="link" onClick={resendCode}>
                  Resend Code
								</Button>
							</Box>
							<Box paddingY="space60">
								<Button variant="primary" type="submit">
                  Verify
								</Button>
							</Box>
						</form>
					</>
				) : (
					<Flex vAlignContent="center" hAlignContent="center">
						<Spinner size="sizeIcon110" decorative={false} title="Loading" />
					</Flex>
				)}
			</Box>
		</Box>
	);
};
