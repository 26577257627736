import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@twilio-paste/core/box";
import { Header } from "../Header/Header";
import { SideNav } from "../Sidenav/Sidenav";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { HEADERS_HEIGHT } from "../../constants";
import { BreadcrumbMaker } from "../BreadcrumbMaker/BreadcrumbMaker";


export const MainContainer: FC = () => {
	return (
		<PrivateRoute>
			<>
				<Header />
				<Box display="flex">
					<SideNav />
					<Box as="main" padding={["space0", "space60", "space100"]} width={[`calc(100vw - ${HEADERS_HEIGHT}`, "100%", "100%"]}>
						<BreadcrumbMaker />
						<Outlet />
					</Box>
				</Box>
			</>
		</PrivateRoute>
	);
};