import { Box } from "@twilio-paste/box";
import { Flex } from "@twilio-paste/flex";
import { Heading } from "@twilio-paste/heading";
import { Input } from "@twilio-paste/core/input";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button } from "@twilio-paste/button";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/table";
import { Campaign, CampaignConfig, Documents, DocumentView } from "@ciptex/ccp";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate, useParams } from "react-router-dom";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { FORM_BACKGROUND } from "../../constants";
import { useToasterContext } from "../../hooks/useToasterContext";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";

export const ManageCampaignDocuments: FC<Campaign> = () => {
	const { campaignId, campaignConfigId } = useParams();
	const navigate = useNavigate();
	const [campaign, setCampaign] = useState<Campaign>();
	const [documents, setDocuments] = useState<Documents>();
	const [documentViews, setDocumentViews] = useState<DocumentView>();
	const [campaignConfig, setCampaignConfig] = useState<CampaignConfig>();
	const [loaded, setLoaded] = useState<boolean>();
	const [viewsLoaded, setViewsLoaded] = useState<boolean>(false);
	const {
		deleteDocument,
		getCampaignConfig,
		listCampaignConfigDocuments,
		getDocumentViews,
		createDocument,
		updateDocument
	} = useCCPContext();

	const { toaster } = useToasterContext();

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		console.log(target.name, target.value )
		if(documents)
		{
			const docs = documents.map((x) => x)
			const index = parseInt(target.name.split('#')[0])
			const name = target.name.split('#')[1]

			docs.splice(index,1,{...docs[index], [name] : target.value})
			setDocuments(docs)
		}


	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		(async () => {
			try {
				// for each row in the table, if there's a docid then update, else create
				for (const d of documents ?? [])
				{
					if(! d.documentId)
					{
						// Create new doc
						await createDocument(d);
					}
					else{
						// Update existing doc
						await updateDocument(d.documentId, d);
					}
				}

				toaster.push({
					message: "Documents updated",
					variant: "success",
					dismissAfter: 4000
				});
				navigate(`/campaigns/edit/${campaignConfig?.campaignId}`);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Documents could not be updated",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	};

	useEffect(() => {
		if (!campaignId || !campaignConfigId) {
			navigate("/campaigns");
			return;
		}
		(async () => {
			try {
				const data: CampaignConfig = await getCampaignConfig(
					parseInt(campaignConfigId ? campaignConfigId: "") ?? 0
				);
				setCampaignConfig(data);
				const data1: Documents = await listCampaignConfigDocuments(
					parseInt(campaignConfigId ? campaignConfigId: "") ?? 0
				);



				const check : any = data1;

				if(check.error_message)
				{
					console.error(check.error_message);
				}
				else{
					setDocuments(data1);
				}
				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve documents",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);



	const handleDelete = async (documentId: number) => {
		if (documents) {
			try {
				await deleteDocument(documentId);
				toaster.push({
					message: "Document has been deleted",
					variant: "success",
					dismissAfter: 4000
				});
				setDocuments(
					documents.filter((document) => document.documentId !== documentId)
				);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Document could not be removed",
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};

	const handleView = async (documentId: number) => {

		setViewsLoaded(false);

		// get document views
		if (documents) {
			try {
				const docViews = await getDocumentViews(documentId);
				setDocumentViews(docViews);
				setViewsLoaded(true);

			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Document access cannot be viewed",
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};


	const addRow = () => {
		if (documents)
		{
			const docs_ = documents.map((x) => x)
			docs_.push({ name : "", url : "", campaignId : campaignConfig?.campaignId, campaignConfigId : campaignConfig?.campaignConfigId })
			setDocuments(docs_)
		}
		else
		{
			const docs_ = []
			docs_.push({ name : "", url : "", campaignId : campaignConfig?.campaignId, campaignConfigId : campaignConfig?.campaignConfigId })
			setDocuments(docs_)
		}

	};

	return (
		<Box width="100%">
			{loaded && <HeaderTitleText titleText={"Manage Campaign Documents - " + campaignConfig?.reference}/>}

			{/* Main box for editable data and SLA table */}
			<Box display={["block", "block", "flex"]}>
				{/* All the left hand side editable data piece */}
				<Box
					display="flex"
					flexWrap="wrap"
					flexDirection="column"
					style={{ background: FORM_BACKGROUND }}
					padding="space60"
					borderRadius="borderRadius30"
					marginBottom="space60"
					marginTop="space40"
					borderStyle="solid"
					borderWidth="borderWidth10"
					borderColor="colorBorder"
					width={["100%", "100%", "100%"]}
				>
					<Box display="flex" justifyContent="space-between" height="40px" >
						<Heading as="h2" variant="heading20">
						Documents
						</Heading>
						<Button variant="primary" onClick={() => addRow()}>
							<PlusIcon decorative={false} title="Add new document" />
					Add New Document
						</Button>
					</Box>

					{documents && loaded ? (
						<form onSubmit={(e) => handleSubmit(e)}>
							<Box paddingY="space60">
								<Table aria-label="Documents table" striped>
									<THead>
										<Tr>
											<Th>Document Name</Th>
											<Th>URL</Th>
											<Th textAlign="right">Actions</Th>
										</Tr>
									</THead>
									<TBody>
										{documents && loaded ? (
											documents &&
						documents.map((document: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td>
									<Input
										onChange={handleChange}
										name={rowIndex+"#name"}
										id="name"
										type="text"
										value = {document.name}
									/>
								</Td>
								<Td>

									<Input
										onChange={handleChange}
										name={rowIndex+"#url"}
										id="url"
										type="text"
										value = {document.url}
									/>
								</Td>
								<Td>
									<Box
										display="flex"
										justifyContent="flex-end"
										flexDirection="row"
										columnGap="space60"
									><Button
											variant="secondary"
											onClick={() => handleView(document.documentId)}
										>
											<ShowIcon decorative={false} title="View Document Access" />
										</Button>
										<Button
											variant="destructive"
											onClick={() => handleDelete(document.documentId)}
										>

											<DeleteIcon decorative={false} title="Delete Document" />
										</Button>
									</Box>
								</Td>
							</Tr>
						))
										) : (
											<TableSkeletonLoader numberOfTd={3} numberOfTr={1} />
										)}
									</TBody>
								</Table>
							</Box>

							<Table aria-label="Document views" striped>
								<THead>
									<Tr>
										<Th>Viewer</Th>
										<Th>Viewed At</Th>
									</Tr>
								</THead>
								<TBody>
									{documentViews && viewsLoaded ? (
										documentViews &&
						documentViews.map((docview: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td>
									{docview.viewer}
								</Td>
								<Td>
									{docview.viewedAt}
								</Td>

							</Tr>
						))
									) : (
										<TableSkeletonLoader numberOfTd={2} numberOfTr={1} />
									)}
								</TBody>
							</Table>


							{/* Button container and buttons */}
							<Box
								display="flex"
								justifyContent="space-between"
								paddingTop="space60"
							>
								<Button
									variant="secondary"
									onClick={() => navigate("/campaigns")}
								>
									Cancel
								</Button>
								<Button type="submit" variant="primary">
									Submit
								</Button>
							</Box>
						</form>
					) : (
						<Flex vAlignContent="center" hAlignContent="center">

						</Flex>
					)}
				</Box>


			</Box>
		</Box>
	);
};
