import { Box } from "@twilio-paste/box";
import { Heading } from "@twilio-paste/heading";
import { Input } from "@twilio-paste/input";
import { Label } from "@twilio-paste/label";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button } from "@twilio-paste/button";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate } from "react-router-dom";
import { Campaign, ClientCore, ClientConfig } from "@ciptex/ccp";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { FORM_BACKGROUND } from "../../constants";
import { Select, Option } from "@twilio-paste/core";
import { HelpText } from "@twilio-paste/core/help-text";

export const AddCampaign: FC = () => {
	const navigate = useNavigate();
	const [campaign, setCampaign] = useState<Campaign>();
	const [clients, setClients] = useState<ClientCore>();
	const [endClients, setEndClients] = useState<any>();
	const { createCampaign, listClients, getClientConfig } = useCCPContext();

	const [ clientIdError, setClientIdError ] = useState<boolean>(false);
	const [ endClientIdError, setEndClientIdError ] = useState<boolean>(false);


	const { toaster } = useToasterContext();

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setCampaign({
			...campaign,
			[target.name]: target.value
		});
	};

	useEffect (() => {
		(async () => {
			try {
				const data: ClientCore = await listClients();
				setClients(data);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	const handleClientChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		(async () => {
			try {
				const data: ClientConfig = await getClientConfig(Number(target.value));
				const newEndClients = [];
				for(const i of data)
				{
					newEndClients.push({ "endClientId" : i.endClientId, "endClientName" : i.endClientName });
				}
				setEndClients(newEndClients);
				setCampaign({
					...campaign,
					"endClientId": undefined
				});

			} catch (error) {
				console.error(error);
			}
		})();
	};


	const handleSubmit = async (e: any) => {
		setClientIdError(false);
		setEndClientIdError(false);
		e.preventDefault();
		(async () => {
			try {
				if(campaign?.endClientId === undefined || campaign?.endClientId === 0)
				{
					setEndClientIdError(true);
				} else {
					await createCampaign(campaign ? campaign : {});
					toaster.push({
						message: "Campaign created",
						variant: "success",
						dismissAfter: 4000
					});
					navigate("/campaigns");
				}
			} catch (error) {
				toaster.push({
					message: "Could not create new campaign",
					variant: "error",
					dismissAfter: 4000
				});
				console.error(error);
			}
		})();
	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Add Campaign" />

			{/* Main box for editable data and SLA table */}
			<Box>
				{/* All the left hand side editable data piece */}
				<Box
					display="flex"
					flexWrap="wrap"
					flexDirection="column"
					style={{ background: FORM_BACKGROUND }}
					padding="space60"
					borderRadius="borderRadius30"
					marginBottom="space60"
					marginTop="space60"
					borderStyle="solid"
					borderWidth="borderWidth10"
					borderColor="colorBorder"
					width="100%"
				>
					<Heading as="h2" variant="heading20">
            Details
					</Heading>
					<form onSubmit={(e) => handleSubmit(e)}>
						<Box
							display="flex"
							width="100%"
							justifyContent="space-between"
							paddingBottom="space60"
						>
							<Box width="40%">
								<Label required htmlFor="name">Name</Label>
								<Input required
									onChange={handleChange}
									id="name"
									name="name"
									type="text"
								/>
							</Box>

							<Box width="40%">
								<Label required htmlFor="reference">Reference</Label>
								<Input required
									onChange={handleChange}
									id="reference"
									name="reference"
									type="text"
								/>
							</Box>
						</Box>

						<Box display="flex" width="100%" justifyContent="space-between">
							<Box width="40%">
								<Label required htmlFor="client">Client</Label>
								<Select onChange={handleClientChange} id="clientId" name="clientId">
									<Option value={"0"} >Select a Client</Option>
									{clients ? (

										clients && clients.map((client: any, index: number ) => (

											<Option key={index} value={client.clientId} >{client.name}</Option>
										))) : null! }
								</Select>
								{clientIdError && <HelpText variant="error">Cannot submit without a Client</HelpText>}

							</Box>

							<Box width="40%">
								<Label required htmlFor="endclient">End Client</Label>
								<Select onChange={handleChange} id="endClientId" name="endClientId" value = {String(campaign?.endClientId)}>
									<Option value={"0"} >Select an End Client</Option>
									{endClients ? (

										endClients && endClients.map((endClient: any, index: number ) => (

											<Option key={index} value={endClient.endClientId} >{endClient.endClientName}</Option>
										))) : null! }
								</Select>
								{endClientIdError && <HelpText variant="error">Cannot submit without an End Client</HelpText>}

							</Box>


						</Box>

						<Box
							display="flex"
							width="100%"
							justifyContent="space-between"
							paddingBottom="space60"
							paddingTop="space60"
						>
							<Box width="40%">
								<Label required htmlFor="invokeTime">Invoke Time</Label>
								<Input required
									onChange={handleChange}
									name="invokeTime"
									id="invokeTime"
									type="text"
									placeholder="72hrs or 120hrs"
									pattern="\b(72HRS|120HRS)|(72hrs|120hrs)\b"
									defaultValue={campaign?.invokeTime}
								/>
							</Box>
						</Box>

						{/* Button container and buttons */}
						<Box
							display="flex"
							justifyContent="space-between"
							paddingTop="space60"
						>
							<Button
								variant="secondary"
								onClick={() => navigate("/campaigns")}
							>
                Cancel
							</Button>
							<Button type="submit" variant="primary">
                Submit
							</Button>
						</Box>
					</form>
				</Box>
			</Box>
		</Box>
	);
};
