import { Dispatch, PropsWithChildren, createContext, useReducer, useState, Context, FC } from "react";
import { appActionTypes, appReducer, appStateTypes, initialAppState } from "../../core/appReducer";
import { useCiptexAuth } from "../../hooks/useCiptexAuth/useCiptexAuth";
import { Token } from "@ciptex/ccp";

export interface StateContextType {
	error: Error | null;
	setError(error: Error | null): void;
	signIn(username: string, password: string): Promise<void>;
	setNewPassword(hostId: string, payload: { resetToken: string; newPassword: string}): Promise<void>
	signOut(): Promise<void>;
	isAuthReady?: boolean;
	appState: appStateTypes;
	appDispatch: Dispatch<appActionTypes>;
	token?: null | Token;
}

export const StateContext: Context<StateContextType> = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
// eslint-disable-next-line @typescript-eslint/ban-types
export const AppStateProvider: FC<{}> = (props: PropsWithChildren<{}>) => {
	const [error, setError] = useState<Error | null>(null);
	const [appState, appDispatch] = useReducer(appReducer, initialAppState);

	const contextValue = {
		error,
		setError,
		appState,
		appDispatch,
		...useCiptexAuth()
	} as StateContextType;

	return (
		<StateContext.Provider value={{ ...contextValue }}>
			{props.children}
		</StateContext.Provider>
	);
}
