import { FC, useEffect, useState } from "react";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { ClientCore } from "@ciptex/ccp";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button } from "@twilio-paste/core/button";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";

export const ClientsTable: FC = () => {

	const navigate = useNavigate();
	const { listClients, deleteClient } = useCCPContext();

	const { toaster } = useToasterContext();

	const [clients, setClients] = useState<ClientCore>();
	const [loaded, setLoaded] = useState<boolean>();

	useEffect (() => {
		(async () => {
			try {
				const data: ClientCore = await listClients();
				setClients(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	const handleDelete = async (clientId: number) => {
		if (clients){
			try {
				await deleteClient(clientId);
				toaster.push({
					message: "Client has been removed",
					variant: "success",
					dismissAfter: 4000
				});
				setClients(clients.filter((client) => client.clientId !== clientId));
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Client could not be removed",
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Clients" />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Button variant="primary" onClick={() => navigate("add") }><PlusIcon decorative={false} title="Add new client" />Add New Client</Button>
			</Box>
			<Table aria-label="Clients table" striped>
				<THead >
					<Tr>
						<Th>Client ID</Th>
						<Th>Name</Th>
						<Th textAlign="right">Actions</Th>
					</Tr>
				</THead>
				<TBody>
					{clients && loaded ? (
						clients && clients.map((client: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td >
									{client.clientId}
								</Td>
								<Td >
									{client.name}
								</Td>
								<Td >
									<Box display="flex" justifyContent="flex-end" flexDirection="row" columnGap="space60">
										<Button variant="secondary" onClick={() => navigate(`edit/${client.clientId}`)}><EditIcon decorative={false} title="Edit client" /></Button>
										<Button variant="destructive" onClick={() => handleDelete(client.clientId)}><DeleteIcon decorative={false} title="Delete client" /></Button>
									</Box>
								</Td>
							</Tr>
						))): <TableSkeletonLoader numberOfTd={3} numberOfTr={1} />}
				</TBody>
			</Table>
		</Box>
	);
};

