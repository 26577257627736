// General
import CCPLogo from "./images/Logo.png";
export const LOGO = CCPLogo;
export const HEADERS_HEIGHT = "64px";
export const LOGO_HEIGHT = "46px";
export const ACCOUNT_SID = process.env.REACT_APP_ACCOUNT_SID || "";
export const TOKEN = process.env.REACT_APP_TOKEN || "";

// Color Section
export const FORM_BACKGROUND = "rgb(255, 255, 255)";
export const SUCCESS = "#64CCC9";
export const UNAVAILABLE = "#003939";
export const RED = "#BD0060";
export const WEAKEST = "#e8f3f1";

// Temp or Junk
export const TableBodyData = [
	["Doc A", "Shaun", "20/02/2022", "Shaun", "22/02/2022"],
	["Doc B", "Caz", "20/02/2022", "Caz", "22/02/2022"]
];
export const TableHeaderData = [
	"Document",
	"Created By",
	"Created Data",
	"Last Modified By",
	"Last Modified Date"
];

export const Role = {
	admin: "ccp_admin",
	client: "ccp_client",
	outsourcer: "ccp_outsourcer"
}

