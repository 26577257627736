import { useState, useEffect, FC } from "react";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { SLA } from "@ciptex/ccp";

import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";

export const Stepper: FC<{
	campaignId?: number;
	endClientId?: number;
}> = ({ campaignId, endClientId }) => {

	const { getEndClientSla, getCampaignSla } = useCCPContext();
	const [data, setData] = useState<any[]>([{}]);

	useEffect(() => {

		if (campaignId !== undefined && campaignId !== 0) {

			(async () => {
				try {
					const sladata: SLA = await getCampaignSla(campaignId);

					const dataAsArrays = [];
					let currentPassed = false;

					for (const d of sladata) {
						let c = "";
						if (currentPassed === true) {
							c = "C";
						} else if (d.isCurrent === 1) {
							c = "B";
							currentPassed = true;
						} else {
							c = "A";
						}

						dataAsArrays.push({
							Number: d.orderId,
							TaskName: d.taskName,
							Icon: c
						});
					}
					setData(dataAsArrays);
				} catch (error) {
					console.error(error);
				}
			})();

		} else if (endClientId !== undefined && endClientId !== 0) {
			(async () => {
				try {
					const sladata: SLA = await getEndClientSla(endClientId);



					const dataAsArrays = [];
					let currentPassed = false;

					for (const d of sladata) {
						let c = "";
						if (currentPassed === true) {
							c = "C";
						} else if (d.isCurrent === 1) {
							c = "B";
							currentPassed = true;
						} else {
							c = "A";
						}

						dataAsArrays.push({
							Number: d.orderId,
							TaskName: d.taskName,
							Icon: c
						});
					}
					setData(dataAsArrays);
				} catch (error) {
					console.error(error);
				}
			})();
		}



	}, []);


	const colorComplete = "#E8F3F1";
	const colorCompleteText = "colorTextInverseWeaker";
	const colorInProgress = "#BD0060";
	const colorInProgressText = "colorTextIconBrandInverse";
	const colorNotStarted = "#64CCCA";
	const colorNotStartedText = "colorTextIconBrandInverse";

	function step(Color: any, Number: any, TextColor: any, TaskText: any, TaskTextWeight: any) {
		return (
			<Box alignItems="center" justifyContent="space-around" width="100px">
				<Box
					borderStyle="solid"
					borderRadius="borderRadiusCircle"
					borderColor="colorBorderInverse"
					height="30px"
					width="30px"
					backgroundColor={Color}
					marginLeft="space90"
				>
					<Text
						fontSize="fontSize40"
						fontWeight="fontWeightBold"
						padding="space10"
						textAlign="center"
						as="p"
						color={TextColor}
					>
						{Number}
					</Text>
				</Box>
				<Text
					fontSize="fontSize30"
					padding="space20"
					fontWeight={TaskTextWeight}
					textAlign="center"
					as="div"
					color="colorText"
				>
					{TaskText}
				</Text>
			</Box>
		);
	}

	const steps = [];

	const divider = (
		<Box
			display="inline-block"
			width="75px"
			borderBottomStyle="solid"
			borderBottomColor="colorBorderWeak"
			paddingTop="space60"
		></Box>
	);

	for (const d of data) {
		if (d.Icon === "A")
			steps.push(
				step(
					colorNotStarted,
					d.Number,
					colorNotStartedText,
					d.TaskName,
					"fontWeightMedium"
				)
			);
		else if (d.Icon === "B")
			steps.push(
				step(
					colorInProgress,
					d.Number,
					colorInProgressText,
					d.TaskName,
					"fontWeightBold"
				)
			);
		else if (d.Icon === "C")
			steps.push(
				step(
					colorComplete,
					d.Number,
					colorCompleteText,
					d.TaskName,
					"fontWeightMedium"
				)
			);

		steps.push(divider);
	}

	steps.pop();

	return (
		<Box display="flex" alignItems="flex-start">
			{steps}
		</Box>
	);
	/* eslint-enable react/no-array-index-key */
};
