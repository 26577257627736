import { Flex } from "@twilio-paste/flex";
import { Heading } from "@twilio-paste/heading";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { Stack } from "@twilio-paste/stack";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Client } from "@ciptex/ccp";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { Spinner } from "@twilio-paste/core/dist/spinner";
import { FORM_BACKGROUND } from "../../constants";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useParams, useNavigate } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";

export const EditClient: FC = () => {
	const { clientId } = useParams();
	const navigate = useNavigate();
	const [client, setClient] = useState<Client>();
	const [loaded, setLoaded] = useState<boolean>();
	const { getClient, updateClient } = useCCPContext();

	const { toaster } = useToasterContext();

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setClient({
			...client,
			[target.name]: target.value
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		(async () => {
			try {
				await updateClient(
					parseInt(clientId ? clientId : ""),
					client ? client : {}
				);
				setLoaded(true);
				toaster.push({
					message: "Client details updated",
					variant: "success",
					dismissAfter: 4000
				});
				navigate("/clients");
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Client details could not be updated",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	};

	useEffect(() => {
		if (!clientId) {
			navigate("/clients");
			return;
		}
		(async () => {
			try {
				const data: Client = await getClient(parseInt(clientId));
				setClient(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space40"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
		>
			<Box>
				<HeaderTitleText titleText="Edit Client" />
				{client && loaded ? (
					<form onSubmit={(e) => handleSubmit(e)}>
						<Box width="30%" paddingBottom="space60">
							<Label htmlFor="name">Client</Label>
							<Input
								id="name"
								name="name"
								type="text"
								onChange={handleChange}
								defaultValue={client.name}
							></Input>
						</Box>

						<Box
							borderRadius="borderRadius20"
							borderStyle="solid"
							borderWidth="borderWidth10"
							borderColor="colorBorder"
							padding="space60"
						>
							<Heading as="h3" variant="heading30">
                Primary Operational Contact
							</Heading>
							<Flex>
								<Box paddingRight="space60">
									<Label required htmlFor="primaryContactName">
                    Name
									</Label>
									<Input
										required
										onChange={handleChange}
										name="primaryContactName"
										id="primaryContactName"
										type="text"
										defaultValue={client.primaryContactName}
									></Input>
								</Box>
								<Box paddingRight="space60">
									<Label required htmlFor="primaryContactEmail">
                    Email
									</Label>
									<Input
										required
										onChange={handleChange}
										name="primaryContactEmail"
										id="primaryContactEmail"
										type="email"
										defaultValue={client.primaryContactEmail}
									></Input>
								</Box>
								<Box paddingRight="space60">
									<Label htmlFor="primaryContactPhone">Phone</Label>
									<Input
										onChange={handleChange}
										id="primaryContactPhone"
										name="primaryContactPhone"
										type="tel"
										defaultValue={client.primaryContactPhone}
									></Input>
								</Box>
							</Flex>
						</Box>

						<Box
							borderRadius="borderRadius20"
							borderStyle="solid"
							borderWidth="borderWidth10"
							borderColor="colorBorder"
							padding="space60"
							marginY="space60"
						>
							<Heading as={"h3"} variant={"heading30"}>
                Secondary Operational Contact
							</Heading>
							<Flex>
								<Box paddingRight="space60">
									<Label htmlFor="secondaryContactName">Name</Label>
									<Input
										onChange={handleChange}
										id="secondaryContactName"
										name="secondaryContactName"
										type="text"
										defaultValue={client.secondaryContactName}
									></Input>
								</Box>
								<Box paddingRight="space60">
									<Label htmlFor="secondaryContactEmail">Email</Label>
									<Input
										onChange={handleChange}
										id="secondaryContactEmail"
										name="secondaryContactEmail"
										type="email"
										defaultValue={client.secondaryContactEmail}
									></Input>
								</Box>
								<Box paddingRight="space60">
									<Label htmlFor="secondaryContactPhone">Phone</Label>
									<Input
										onChange={handleChange}
										id="secondaryContactPhone"
										name="secondaryContactPhone"
										type="tel"
										defaultValue={client.secondaryContactPhone}
									></Input>
								</Box>
							</Flex>
						</Box>

						<Stack orientation="horizontal" spacing="space200">
							<Button variant="secondary" onClick={() => navigate("/clients")}>
                Cancel
							</Button>
							<Button type="submit" variant="primary">
                Save Amendments
							</Button>
						</Stack>
					</form>
				) : (
					<Flex vAlignContent="center" hAlignContent="center">
						<Spinner size="sizeIcon110" decorative={false} title="Loading" />
					</Flex>
				)}
			</Box>
		</Box>
	);
};
