import { Box } from "@twilio-paste/box";
import { Flex } from "@twilio-paste/flex";
import { Heading } from "@twilio-paste/heading";
import { Input } from "@twilio-paste/input";
import { Label } from "@twilio-paste/label";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Select, Option } from "@twilio-paste/select";
import { TextArea } from "@twilio-paste/textarea";
import { Button } from "@twilio-paste/button";
import { Table, Th, THead, Tr, TBody, Td } from "@twilio-paste/table";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate, useParams } from "react-router-dom";
import { Outsourcer, Languages } from "@ciptex/ccp";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { FORM_BACKGROUND } from "../../constants";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Spinner } from "@twilio-paste/core/spinner";

export const ManageOutsourcer: FC = () => {
	const { outsourcerId } = useParams();
	const navigate = useNavigate();
	const [outsourcer, setOutsourcer] = useState<any>();
	const [loaded, setLoaded] = useState<boolean>();
	const { getOutsourcer, updateOutsourcer, listLanguages } = useCCPContext();
	const [languages, setLanguages] = useState<any[]>();
	const [languageList, setLanguageList] = useState<Languages>();
	const { toaster } = useToasterContext();

	const getDate = (date: string) => {
		const d = date.split(" ");
		return d[0];
	};

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if(target.name === 'otherIdentityProvider')
		{
			setOutsourcer({
				...outsourcer,
				identityProvider: target.value
			});
		}
		else{
			setOutsourcer({
				...outsourcer,
				[target.name]: target.value
			});
		}
		
	};

	const handleTextChange = (e: any) => {
		setOutsourcer({
			...outsourcer,
			[e.target.name]: e.target.value
		});
	};

	const handleLanguagesChange = (index: number, e: any) => {
		console.log('handleLanguagesChange', index, e.target.value, e.target.name);

		if (languages) {
			const newLanguages = languages.map((x) => x)
			newLanguages[index][e.target.name] = e.target.value;
			setLanguages(newLanguages)
			
		} else {
			const newLanguages : any[] = [];
			newLanguages[index][e.target.name] = e.target.value;
			setLanguages(newLanguages)
		}
		console.log('languages', languages);
	};

	const handleLocationsChange = (index: number, e: any) => {
		if (outsourcer?.locations) {
			const newLocations: any = [...outsourcer.locations];
			newLocations[index][e.target.name] = e.target.value;
			setOutsourcer({
				...outsourcer,
				locations: newLocations
			});
		} else {
			const newLocations: any = [];
			newLocations[index][e.target.name] = e.target.value;
			setOutsourcer({
				...outsourcer,
				locations: newLocations
			});
		}
	};

	const addLanguageRow = () => {
		if (languages) {
			const l = languages.map((x) => x)
			l.push({ language: (languageList ?? [])[0].name,
				invokeTime: "",
				numberOfSeats: '',
				type : "Current",
				outsourcerLanguageId : - Math.floor(Math.random() * 1000 + 1) })
			setLanguages(l)
			
		} else {
			const l = []
			l.push({ language: (languageList ?? [])[0].name,
				invokeTime: "",
				numberOfSeats: '',
				type : "Current",
				outsourcerLanguageId : - Math.floor(Math.random() * 1000 + 1) })
			setLanguages(l)
		}
	};

	const removeLanguageRow = (outsourcerLanguageId: number) => {
		if (languages) {
			
				
			const newLanguages = languages.filter(
				(i: any) => i.outsourcerLanguageId !== outsourcerLanguageId
			)
			setLanguages(newLanguages)
			
		}
	};

	const addLocationRow = () => {
		if (outsourcer?.locations) {
			setOutsourcer({
				...outsourcer,
				locations: [
					...outsourcer.locations,
					{
						outsourcerLocationId: Math.floor(Math.random() * 1000 + 1),
						name: "",
						timezone: "",
						utcOffset: 0
					}
				]
			});
		} else {
			setOutsourcer({
				...outsourcer,
				locations: [
					{
						outsourcerLocationId: Math.floor(Math.random() * 1000 + 1),
						name: "",
						timezone: "",
						utcOffset: 0
					}
				]
			});
		}
	};

	const removeLocationRow = (outsourcerLocationId: number) => {
		if (outsourcer?.locations) {
			setOutsourcer({
				...outsourcer,
				locations: outsourcer.locations.filter(
					(i: any) => i.outsourcerLocationId !== outsourcerLocationId
				)
			});
		}
	};

	const handleSubmit = async (e: any) => {
		console.log('outsourcer',outsourcer);
		e.preventDefault();
		(async () => {
			try {


				if(languages)
				{
					console.log('languages',languages);
					//structure languages
					const newLanguagesCurrent = languages.filter(
						(i: any) => i.type !== 'Current'
					)

					const newLanguagesContracted = languages.filter(
						(i: any) => i.type !== 'Contracted'
					)

					console.log('newLanguagesCurrent',newLanguagesCurrent);
					console.log('newLanguagesContracted',newLanguagesContracted);

					const newOutsourcer = 
						{
							...outsourcer,
							languagesContracted: newLanguagesContracted,
							languagesCurrent: newLanguagesCurrent
						}

					console.log('newOutsourcer',newOutsourcer);

					await updateOutsourcer(
						parseInt(outsourcerId ? outsourcerId : ""),
						outsourcer ? newOutsourcer : {}
					);
					setLoaded(true);
					toaster.push({
						message: "Outsourcer details updated",
						variant: "success",
						dismissAfter: 4000
					});
					navigate("/outsourcers");
				}
				else
				{
					await updateOutsourcer(
						parseInt(outsourcerId ? outsourcerId : ""),
						outsourcer ? outsourcer : {}
					);
					setLoaded(true);
					toaster.push({
						message: "Outsourcer details updated",
						variant: "success",
						dismissAfter: 4000
					});
					navigate("/outsourcers");
				}


			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Outsourcer details could not be updated",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	};

	useEffect(() => {
		if (!outsourcerId) {
			navigate("/outsourcers");
			return;
		}
		(async () => {
			try {
				const data: Outsourcer = await getOutsourcer(parseInt(outsourcerId));
				console.log('data', data);
				setOutsourcer(data);

				//collate outsourcer language data
				let newLanguages = [];
				for ( const  l of data.languagesContracted ?? [])
				{
					newLanguages.push({language : l.language, invokeTime : l.invokeTime, numberOfSeats : l.numberOfSeats, type : 'Contracted', outsourcerLanguageId : l.outsourcerLanguageId})
				}
				for ( const  l of data.languagesCurrent ?? [])
				{
					newLanguages.push({language : l.language, invokeTime : l.invokeTime, numberOfSeats : l.numberOfSeats, type : 'Current', outsourcerLanguageId : l.outsourcerLanguageId})
				}

				console.log('newLanguages',newLanguages);
				setLanguages(newLanguages);

				//get reference data
				const langs = await listLanguages();
				setLanguageList(langs);


				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	return (
		<>
			<Box
				display="flex"
				flexWrap="wrap"
				flexDirection="column"
				style={{ background: FORM_BACKGROUND }}
				padding="space60"
				borderRadius="borderRadius30"
				marginBottom="space60"
				marginTop="space40"
				borderStyle="solid"
				borderWidth="borderWidth10"
				borderColor="colorBorder"
			>
				<HeaderTitleText titleText="Manage Outsourcer" />
				<Box width="100%">
					{outsourcer && loaded ? (
						<form onSubmit={(e) => handleSubmit(e)}>
							<Flex paddingBottom="space40" wrap>
								<Box
									width={["100%", "100%", "50%"]}
									paddingRight={["space0", "space0", "space140"]}
									paddingBottom={["space40", "space40", "space0"]}
								>
									<Label htmlFor="name">Name</Label>
									<Input
										onChange={handleChange}
										name="name"
										id="name"
										type="text"
										defaultValue={outsourcer.name}
									></Input>
								</Box>
								<Box width={["100%", "100%", "50%"]} paddingRight="space0">
									<Label htmlFor="clientname">Client</Label>
									<Input
										disabled
										onChange={handleChange}
										name="clientname"
										id="clientname"
										type="text"
										defaultValue={outsourcer.client}
									></Input>
								</Box>
							</Flex>

							<Flex paddingBottom="space40" wrap>
								<Box
									width={["100%", "100%", "50%"]}
									paddingRight={["space0", "space0", "space140"]}
									paddingBottom={["space40", "space40", "space0"]}
								>
									<Label htmlFor="status">Status</Label>
									<Input
										disabled
										onChange={handleChange}
										name="status"
										id="status"
										type="text"
										value="New"
										defaultValue={outsourcer.status}
									></Input>
								</Box>
								<Box width={["100%", "100%", "50%"]} paddingRight="space0">
									<Label htmlFor="twilioAccountSid">Twilio Account SID</Label>
									<Input
										disabled
										onChange={handleChange}
										name="twilioAccountSid"
										id="twilioAccountSid"
										type="text"
										defaultValue={outsourcer.twilioAccountSid}
									></Input>
								</Box>
							</Flex>

							<Flex paddingBottom="space40" wrap>
								<Box
									width={["100%", "100%", "50%"]}
									paddingRight={["space0", "space0", "space140"]}
									paddingBottom={["space40", "space40", "space0"]}
								>
									<Label htmlFor="identityProvider">Identity Provider</Label>
									<Select onChange={handleChange} name="identityProvider" id="identityProvider" value =  {outsourcer.identityProvider}>
										<Option value="Azure AD">Azure AD</Option>
										<Option value="Okta">Okta</Option>
										<Option value="Other">Other</Option>
									</Select>
								</Box>
								<Box width={["100%", "100%", "50%"]} paddingRight="space0">
									<Label htmlFor="otherIdentityProvider">
                    Other Identity Provider
									</Label>
									<Input
										onChange={handleChange}
										name="otherIdentityProvider"
										id="otherIdentityProvider"
										type="text"
										defaultValue={!['Azure AD', 'Okta'].includes(outsourcer.identityProvider) && outsourcer.identityProvider || '' }
									></Input>
								</Box>
							</Flex>

							<Flex paddingBottom="space40" wrap>
								<Box
									width={["100%", "100%", "50%"]}
									paddingRight={["space0", "space0", "space140"]}
									paddingBottom={["space40", "space40", "space0"]}
								>
									<Label htmlFor="availableCapacityMin">
                    Available Minimum Capacity
									</Label>
									<Input
										onChange={handleChange}
										name="availableCapacityMin"
										id="availableCapacityMin"
										type="number"
										defaultValue={outsourcer.availableCapacityMin}
									></Input>
								</Box>
								<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
							paddingBottom={["space40", "space40", "space0"]}
						>
							<Label htmlFor="availableCapacityMax">
								Available Maximum Capacity
							</Label>
							<Input
								onChange={handleChange}
								name="availableCapacityMax"
								id="availableCapacityMax"
								type="number"
								defaultValue={outsourcer.availableCapacityMax}
							></Input>
						</Box>
								
							</Flex>
							<Flex paddingBottom="space40" wrap>
							<Box width={["100%", "100%", "50%"]} paddingRight="space0">
									<Label htmlFor="agreementStartDate">
                    Agreement Start Date
									</Label>
									<Input
										disabled
										onChange={handleChange}
										name="agreementStartDate"
										id="agreementStartDate"
										type="date"
										defaultValue={getDate(
											outsourcer.agreementStartDate
												? outsourcer.agreementStartDate
												: ""
										)}
									></Input>
								</Box>
								</Flex>
							<Box
								borderRadius="borderRadius20"
								borderStyle="solid"
								borderWidth="borderWidth10"
								borderColor="colorBorder"
								padding="space60"
							>
								<Heading as="h3" variant="heading30">
                  Primary Operational Contact
								</Heading>
								<Box
									display={["row", "row", "flex"]}
									justifyContent="space-between"
								>
									<Box paddingRight={["space0", "space0", "space100"]}>
										<Label htmlFor="primaryOpsContactName">Name</Label>
										<Input
											onChange={handleChange}
											name="primaryOpsContactName"
											id="primaryOpsContactName"
											type="text"
											defaultValue={outsourcer.primaryOpsContactName}
										></Input>
									</Box>
									<Box paddingRight={["space0", "space0", "space100"]}>
										<Label htmlFor="primaryOpsContactEmail">Email</Label>
										<Input
											onChange={handleChange}
											name="primaryOpsContactEmail"
											id="primaryOpsContactEmail"
											type="email"
											defaultValue={outsourcer.primaryOpsContactEmail}
										></Input>
									</Box>
									<Box>
										<Label htmlFor="primaryOpsContactPhone">Phone</Label>
										<Input
											onChange={handleChange}
											id="primaryOpsContactPhone"
											name="primaryOpsContactPhone"
											type="text"
											defaultValue={outsourcer.primaryOpsContactPhone}
										></Input>
									</Box>
								</Box>
							</Box>

							<Box
								borderRadius="borderRadius20"
								borderStyle="solid"
								borderWidth="borderWidth10"
								borderColor="colorBorder"
								padding="space60"
								marginTop="space80"
								marginBottom="space80"
							>
								<Heading as="h3" variant="heading30">
                  Secondary Operational Contact
								</Heading>
								<Box
									display={["row", "row", "flex"]}
									justifyContent="space-between"
								>
									<Box paddingRight={["space0", "space0", "space100"]}>
										<Label htmlFor="secondaryOpsContactName">Name</Label>
										<Input
											onChange={handleChange}
											id="secondaryOpsContactName"
											name="secondaryOpsContactName"
											type="text"
											defaultValue={outsourcer.secondaryOpsContactName}
										></Input>
									</Box>
									<Box paddingRight={["space0", "space0", "space100"]}>
										<Label htmlFor="secondaryOpsContactEmail">Email</Label>
										<Input
											onChange={handleChange}
											id="secondaryOpsContactEmail"
											name="secondaryOpsContactEmail"
											type="email"
											defaultValue={outsourcer.secondaryOpsContactEmail}
										></Input>
									</Box>
									<Box>
										<Label htmlFor="secondaryOpsContactPhone">Phone</Label>
										<Input
											onChange={handleChange}
											id="secondaryOpsContactPhone"
											name="secondaryOpsContactPhone"
											type="tel"
											defaultValue={outsourcer.secondaryOpsContactPhone}
										></Input>
									</Box>
								</Box>
							</Box>

							<Box
								borderRadius="borderRadius20"
								borderStyle="solid"
								borderWidth="borderWidth10"
								borderColor="colorBorder"
								padding="space60"
								marginTop="space80"
								marginBottom="space80"
							>
								<Heading as="h3" variant="heading30">
                  Out of Hours Contact
								</Heading>
								<Box display="flex" justifyContent="space-between">
									<Box paddingRight="space100">
										<Label htmlFor="outOfHoursContactName">Name</Label>
										<Input
											onChange={handleChange}
											id="outOfHoursContactName"
											name="outOfHoursContactName"
											type="text"
											defaultValue={outsourcer.outOfHoursContactName}
										></Input>
									</Box>
									<Box paddingRight="space100">
										<Label htmlFor="outOfHoursContactEmail">Email</Label>
										<Input
											onChange={handleChange}
											id="outOfHoursContactEmail"
											name="outOfHoursContactEmail"
											type="email"
											defaultValue={outsourcer.outOfHoursContactEmail}
										></Input>
									</Box>
									<Box>
										<Label htmlFor="outOfHoursContactPhone">Phone</Label>
										<Input
											onChange={handleChange}
											id="outOfHoursContactPhone"
											name="outOfHoursContactPhone"
											type="text"
											defaultValue={outsourcer.outOfHoursContactPhone}
										></Input>
									</Box>
								</Box>
							</Box>

							<Box paddingBottom="space40">
								<Label htmlFor="escalationProcess">Escalation Process</Label>
								<TextArea
									onChange={(e) => {
										handleTextChange(e);
									}}
									placeholder="Add text"
									id="escalationProcess"
									name="escalationProcess"
									defaultValue={outsourcer.escalationProcess}
								/>
							</Box>

							<Box paddingBottom="space40">
								<Label htmlFor="ccpComment">CCP Comment</Label>
								<TextArea
									onChange={(e) => {
										handleTextChange(e);
									}}
									placeholder="Add text"
									id="ccpComment"
									name="ccpComment"
									defaultValue={outsourcer.ccpComment}
								/>
							</Box>

							<Box marginY="space60">
								<Box
									display="flex"
									justifyContent="space-between"
									marginY="space60"
									alignItems="center"
								>
									<Heading as="h3" variant="heading30">
                    Languages
									</Heading>
									<Button
										value="Add another language"
										variant="primary"
										onClick={() => addLanguageRow()}
									>
                    Add Language
									</Button>
								</Box>

								<Table>
									<THead>
										<Tr>
											<Th textAlign="center">Language</Th>
											<Th textAlign="center">Invoke Time</Th>
											<Th textAlign="center">Number of Seats</Th>
											<Th textAlign="center">Type</Th>
											<Th textAlign="right">Actions</Th>
										</Tr>
									</THead>
									<TBody>
										{languages && loaded
											? languages.map(
												(language: any, index: number) => (
													<Tr key={language.outsourcerLanguageId}>
														<Td>
															
															<Select
																
																onChange={(e) =>
																	handleLanguagesChange(index, e)
																}
																name="language"
																id="language"
																defaultValue = {language.language}
															>
																{languageList
																	? languageList &&
																	languageList.map((languageL: any, index: number) => (
																		<Option
																			
																			key={index}
																			value={languageL.name}
																		>
																			{languageL.name}
																		</Option>
																	))
																	: null!}
															</Select>
														</Td>
														<Td>
															<Input
																type="text"
																name="invokeTime"
																placeholder="72hrs or 120hrs"
																pattern="\b(72HRS|120HRS)|(72hrs|120hrs)\b"
																defaultValue={language.invokeTime}
																onChange={(e) =>
																	handleLanguagesChange(index, e)
																}
															/>
														</Td>
														<Td>
															<Input
																placeholder="40"
																type="number"
																name="numberOfSeats"
																defaultValue={language.numberOfSeats}
																onChange={(e) =>
																	handleLanguagesChange(index, e)
																}
															></Input>
														</Td>
														<Td>
														<Select onChange={(e) =>
																	handleLanguagesChange(index, e)
																} name="type"
																defaultValue={language.type}>
															<Option value="A">Current</Option>
															<Option value="A">Contracted</Option>
														</Select>
															
														</Td>
														<Td textAlign="right">
															<Box>
																<Button
																	variant="destructive"
																	onClick={() =>
																		removeLanguageRow(
																			language.outsourcerLanguageId
																		)
																	}
																>
                                    Remove Language
																</Button>
															</Box>
														</Td>
													</Tr>
												)
											)
											: ""}
									</TBody>
								</Table>
							</Box>

							<Box paddingTop="space60">
								<Box
									display="flex"
									justifyContent="space-between"
									marginY="space60"
									alignItems="center"
								>
									<Heading as="h3" variant="heading30">
                    Locations
									</Heading>

									<Button variant="primary" onClick={() => addLocationRow()}>
                    Add Location
									</Button>
								</Box>

								<Table id="languageTable">
									<THead>
										<Tr>
											<Th textAlign="center">Name</Th>
											<Th textAlign="center">Timezone</Th>
											<Th textAlign="center">UTC Offset</Th>
											<Th textAlign="right">Actions</Th>
										</Tr>
									</THead>
									<TBody>
										{outsourcer?.locations
											? outsourcer.locations.map(
												(location: any, index: number) => (
													<Tr key={location.outsourcerLocationId}>
														<Td>
															<Input
																placeholder="Enter name..."
																name="name"
																defaultValue={location.name}
																type="text"
																onChange={(e) =>
																	handleLocationsChange(index, e)
																}
															/>
														</Td>
														<Td>
															<Input
																name="timezone"
																id="timezone"
																defaultValue={location.timezone}
																type="text"
																onChange={(e) =>
																	handleLocationsChange(index, e)
																}
															/>
														</Td>
														<Td>
															<Input
																name="utcOffset"
																id="utcOffset"
																type="text"
																defaultValue={location.utcOffset}
																onChange={(e) =>
																	handleLocationsChange(index, e)
																}
															></Input>
														</Td>
														<Td textAlign="right">
															<Box>
																<Button
																	variant="destructive"
																	onClick={() =>
																		removeLocationRow(
																			location.outsourcerLocationId
																		)
																	}
																>
                                    Remove Contract
																</Button>
															</Box>
														</Td>
													</Tr>
												)
											)
											: ""}
									</TBody>
								</Table>
							</Box>

							<Box
								display="flex"
								justifyContent="flex-start"
								paddingTop="space60"
								columnGap="space60"
							>
								<Button
									variant="secondary"
									onClick={() => navigate("/outsourcers")}
								>
                  Cancel
								</Button>
								<Button type="submit" variant="primary">
                  Submit
								</Button>
							</Box>
						</form>
					) : (
						<Flex vAlignContent="center" hAlignContent="center">
							<Spinner size="sizeIcon110" decorative={false} title="Loading" />
						</Flex>
					)}
				</Box>
			</Box>
		</>
	);
};
