import { useState, useEffect, FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { SLA } from "@ciptex/ccp";

import { ProcessNeutralIcon } from "@twilio-paste/icons/esm/ProcessNeutralIcon";
import { ProcessWarningIcon } from "@twilio-paste/icons/esm/ProcessWarningIcon";
import { ProcessSuccessIcon } from "@twilio-paste/icons/esm/ProcessSuccessIcon";

import {
	DataGrid,
	DataGridHead,
	DataGridRow,
	DataGridHeader,
	DataGridBody,
	DataGridCell
} from "@twilio-paste/core/data-grid";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";

export const SLATable: FC<{ campaignId: number; endClientId: number }> = ({
	campaignId,
	endClientId
}) => {

	const { getEndClientSla, getCampaignSla } = useCCPContext();
	const [data, setData] = useState<any[]>([{}]);
	const [loaded, setLoaded] = useState<boolean>();

	const headers = [];
	const headersList = ["Task", "Due Date", "Actual Date", "SLA Met"];

	for (const i in headersList) {
		headers.push(
			<DataGridHeader data-testid="header">
				<Box display="flex" alignItems="center" columnGap="space20">
					{headersList[i]}
				</Box>
			</DataGridHeader>
		);
	}

	useEffect(() => {
		if (campaignId !== undefined && campaignId !== 0) {

			(async () => {
				try {
					const sladata: SLA = await getCampaignSla(campaignId);


					const dataAsArrays = Array<any>();
					for (const d of sladata) {
						const a = [];

						a.push(d.taskName);
						a.push(d.dueDate);
						a.push(d.completedDate);
						a.push(d.status);

						dataAsArrays.push(a);
					}


					for (const d of dataAsArrays) {
						if (d[3] === "missed") {
							d.pop();
							d.push(
								<ProcessWarningIcon decorative={true} color="colorTextIconError" />
							);
						} else if (d[3] === "met") {
							d.pop();
							d.push(
								<ProcessSuccessIcon decorative={true} color="colorTextIconSuccess" />
							);
						} else if (d[3] === "notstarted") {
							d.pop();
							d.push(
								<ProcessNeutralIcon decorative={true} color="colorTextIconOffline" />
							);
						}
					}

					setData(dataAsArrays);
					setLoaded(true);


				} catch (error) {
					console.error(error);
				}
			})();

		} else if (endClientId !== undefined && endClientId !== 0) {
			(async () => {
				try {
					const sladata: SLA = await getEndClientSla(endClientId);

					const dataAsArrays = Array<any>();
					for (const d of sladata) {
						const a = [];

						a.push(d.taskName);
						a.push(d.dueDate);
						a.push(d.completedDate);
						a.push(d.status);

						dataAsArrays.push(a);
					}

					for (const d of dataAsArrays) {
						if (d[3] === "missed") {
							d.pop();
							d.push(
								<ProcessWarningIcon decorative={true} color="colorTextIconError" />
							);
						} else if (d[3] === "met") {
							d.pop();
							d.push(
								<ProcessSuccessIcon decorative={true} color="colorTextIconSuccess" />
							);
						} else if (d[3] === "notstarted") {
							d.pop();
							d.push(
								<ProcessNeutralIcon decorative={true} color="colorTextIconOffline" />
							);
						}
					}

					setData(dataAsArrays);
					setLoaded(true);


				} catch (error) {
					console.error(error);
				}
			})();
		}
	}, []);

	return (
		<Box padding="space40">
			{data.length > 0 && (
				<DataGrid aria-label="sla grid">
					<DataGridHead>
						<DataGridRow>{headers}</DataGridRow>
					</DataGridHead>
					{loaded &&<DataGridBody>
						{data.map((row: any[], rowIndex: number) => (
							<DataGridRow key={"row-" + rowIndex}>
								{row.map((col, colIndex) => (
									<DataGridCell key={"sla-"+colIndex}>
										{col}
									</DataGridCell>
								))}
							</DataGridRow>
						))}
					</DataGridBody>}
				</DataGrid>
			)}
		</Box>
	);
}
