import { FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/box";
import { Input } from "@twilio-paste/input";
import { Button } from "@twilio-paste/button";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { Documents, DocumentView } from "@ciptex/ccp";
import { useNavigate, useParams } from "react-router-dom";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/table";
import { FileIcon } from "@twilio-paste/icons/esm/FileIcon";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { useAppState } from "../../hooks/useAppState/useAppState";

export const CampaignReference: FC = () => {
	const { campaignConfigId } = useParams();
	const navigate = useNavigate();
	const [loaded, setLoaded] = useState<boolean>();
	const [documents, setDocuments] = useState<Documents>();

	const { appState } = useAppState();

	const {

		listCampaignConfigDocuments,
		createDocumentViewRecord

	} = useCCPContext();


	const { toaster } = useToasterContext();

	useEffect(() => {
		if (!campaignConfigId) {
			navigate("/documents");
			return;
		}
		(async () => {
			try {
				const data: Documents = await listCampaignConfigDocuments(
					parseInt(campaignConfigId ? campaignConfigId: "") ?? 0
				);

				const check : any = data;

				if(check.error_message)
				{
					console.error(check.error_message);
				}
				else{
					setDocuments(data);
				}
				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve documents",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const handleView = async (docId: number, url : string) => {
		const vw : any = {documentId : docId, viewedAt : new Date(Date.now()), versionNumber : -1}
		createDocumentViewRecord(docId, vw);
		window.open(url);
	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Campaign Reference" />
			<Box paddingY="space60">
								<Table aria-label="Documents table" striped>
									<THead>
										<Tr>
											<Th>Document Name</Th>
											<Th textAlign="right">Actions</Th>
										</Tr>
									</THead>
									<TBody>
										{documents && loaded ? (
											documents &&
						documents.map((document: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td>
								{document.name}
								</Td>
								
								<Td>
									<Box
										display="flex"
										justifyContent="flex-end"
										flexDirection="row"
										columnGap="space60"
									><Button
											variant="secondary"
											onClick={() => handleView(document.documentId, document.url)}
										>
											<FileIcon decorative={false} title="Open Document" />
										</Button>
										
									</Box>
								</Td>
							</Tr>
						))
										) : (
											<TableSkeletonLoader numberOfTd={3} numberOfTr={1} />
										)}
									</TBody>
								</Table>
							</Box>
				
			
		</Box>
	);
};
