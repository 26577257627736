import { Box } from "@twilio-paste/box";
import { Heading } from "@twilio-paste/heading";
import { Input } from "@twilio-paste/input";
import { Label } from "@twilio-paste/label";
import { ChangeEvent, FC, useState } from "react";
import { Button } from "@twilio-paste/button";
// import { EditEndClientSLA } from "./EditEndClientSLA";
import { Client } from "@ciptex/ccp";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate } from "react-router-dom";
import { FORM_BACKGROUND } from "../../constants";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Flex } from "@twilio-paste/flex";
import { Stack } from "@twilio-paste/stack";

export const AddClient: FC = () => {
	const navigate = useNavigate();
	const [client, setClient] = useState<Client>();
	const { createClient } = useCCPContext();

	const { toaster } = useToasterContext();

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setClient({
			...client,
			[target.name]: target.value
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		(async () => {
			try {
				await createClient(client ? client : {});
				toaster.push({
					message: "Client created",
					variant: "success",
					dismissAfter: 4000
				});
				navigate("/clients");
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not create new client",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	};

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space40"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
			width="40%"
		>
			<Box>
				<HeaderTitleText titleText="Create New Client" />
				<form onSubmit={(e) => handleSubmit(e)}>
					<Box width="30%" paddingBottom="space60">
						<Label htmlFor="name">Client</Label>
						<Input
							id="name"
							name="name"
							type="text"
							onChange={handleChange}
						></Input>
					</Box>

					<Box
						borderRadius="borderRadius20"
						borderStyle="solid"
						borderWidth="borderWidth10"
						borderColor="colorBorder"
						padding="space60"
					>
						<Heading as="h3" variant="heading30">
              Primary Operational Contact
						</Heading>
						<Flex>
							<Box paddingRight="space60">
								<Label required htmlFor="primaryContactName">
                  Name
								</Label>
								<Input
									required
									onChange={handleChange}
									name="primaryContactName"
									id="primaryContactName"
									type="text"
								></Input>
							</Box>
							<Box paddingRight="space60">
								<Label required htmlFor="primaryContactEmail">
                  Email
								</Label>
								<Input
									required
									onChange={handleChange}
									name="primaryContactEmail"
									id="primaryContactEmail"
									type="email"
								></Input>
							</Box>
							<Box paddingRight="space60">
								<Label htmlFor="primaryContactPhone">Phone</Label>
								<Input
									onChange={handleChange}
									id="primaryContactPhone"
									name="primaryContactPhone"
									type="tel"
								></Input>
							</Box>
						</Flex>
					</Box>

					<Box
						borderRadius="borderRadius20"
						borderStyle="solid"
						borderWidth="borderWidth10"
						borderColor="colorBorder"
						padding="space60"
						marginY="space60"
					>
						<Heading as={"h3"} variant={"heading30"}>
              Secondary Operational Contact
						</Heading>
						<Flex>
							<Box paddingRight="space60">
								<Label htmlFor="secondaryContactName">Name</Label>
								<Input
									onChange={handleChange}
									id="secondaryContactName"
									name="secondaryContactName"
									type="text"
								></Input>
							</Box>
							<Box paddingRight="space60">
								<Label htmlFor="secondaryContactEmail">Email</Label>
								<Input
									onChange={handleChange}
									id="secondaryContactEmail"
									name="secondaryContactEmail"
									type="email"
								></Input>
							</Box>
							<Box paddingRight="space60">
								<Label htmlFor="secondaryContactPhone">Phone</Label>
								<Input
									onChange={handleChange}
									id="secondaryContactPhone"
									name="secondaryContactPhone"
									type="tel"
								></Input>
							</Box>
						</Flex>
					</Box>

					<Stack orientation="horizontal" spacing="space200">
						<Button variant="secondary" onClick={() => navigate("/clients")}>
              Cancel
						</Button>
						<Button type="submit" variant="primary">
              Save Amendments
						</Button>
					</Stack>
				</form>
			</Box>
		</Box>
	);
};
