import { FC, useEffect, useState } from "react";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { OutsourcerCore } from "@ciptex/ccp";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@twilio-paste/core/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";

export const OutsourcersTable: FC = () => {
	const navigate = useNavigate();
	const { listOutsourcers, deleteOutsourcer } = useCCPContext();

	const { toaster } = useToasterContext();

	const [outsourcers, setOutsourcers] = useState<OutsourcerCore>();
	const [loaded, setLoaded] = useState<boolean>();

	useEffect(() => {
		(async () => {
			try {
				const data: OutsourcerCore = await listOutsourcers();
				setOutsourcers(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	const handleDelete = async (outsourcerId: number) => {
		if (outsourcers) {
			try {
				await deleteOutsourcer(outsourcerId);
				toaster.push({
					message: "Outsourcer has been removed",
					variant: "success",
					dismissAfter: 4000
				});
				setOutsourcers(
					outsourcers.filter(
						(outsourcer) => outsourcer.outsourcerId !== outsourcerId
					)
				);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Outsourcer could not be removed",
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Outsourcers" />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Button variant="primary" onClick={() => navigate("add")}>
					<PlusIcon decorative={false} title="Add new outsourcer" />
					Add New Outsourcer
				</Button>
			</Box>
			<Table aria-label="Outsourcer table" striped>
				<THead>
					<Tr>
						<Th>Outsourcer ID</Th>
						<Th>Client</Th>
						<Th>Name</Th>
						<Th>Status</Th>
						<Th>Agreement Start Date</Th>
						<Th>Available Capacity Minimum</Th>
						<Th>Available Capacity Maximum</Th>
						<Th>Languages</Th>
						<Th textAlign="right">Actions</Th>
					</Tr>
				</THead>
				<TBody>
					{outsourcers && loaded ? (
						outsourcers &&
						outsourcers.map((outsourcers: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td>{outsourcers.outsourcerId}</Td>
								<Td>{outsourcers.client}</Td>
								<Td>{outsourcers.name}</Td>
								<Td>{outsourcers.status}</Td>
								<Td>{outsourcers.agreementStartDate}</Td>
								<Td>{outsourcers.availableCapacityMin}</Td>
								<Td>{outsourcers.availableCapacityMax}</Td>
								<Td>{outsourcers.allLanguages}</Td>
								<Td>
									<Box
										display="flex"
										justifyContent="flex-end"
										flexDirection="row"
										columnGap="space60"
									>
										<Button
											variant="secondary"
											onClick={() =>
												navigate(`edit/${outsourcers.outsourcerId}`)
											}
										>
											<EditIcon decorative={false} title="Edit outsourcer" />
										</Button>
										<Button
											variant="destructive"
											onClick={() => handleDelete(outsourcers.outsourcerId)}
										>
											<DeleteIcon
												decorative={false}
												title="Delete outsourcer"
											/>
										</Button>
									</Box>
								</Td>
							</Tr>
						))
					) : (
						<TableSkeletonLoader numberOfTd={9} numberOfTr={1} />
					)}
				</TBody>
			</Table>
		</Box>
	);
};
