import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@twilio-paste/core/box";
import { HEADERS_HEIGHT } from "../../constants";


export const NonAuthMainContainer: FC = () => {
	return (
		<>
			<Box display="flex">
				<Box as="main" padding={["space0", "space60", "space100"]} width={[`calc(100vw - ${HEADERS_HEIGHT}`, "100%", "100%"]}>
					<Outlet />
				</Box>
			</Box>
		</>
	);
};