import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/table";
import { Text } from "@twilio-paste/text";
import { FC } from "react";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DocumentsGrid } from "./DocumentsGrid";

export const ManageDocuments: FC = () => {
	return (
		<Box width="100%">
			<HeaderTitleText titleText="Manage Documents"/>
			{/* Documents add piece */}
			<Box
				width="80vw"
				paddingBottom="space40"
				borderRadius="borderRadius20"
				display="flex"
				borderStyle="solid"
				borderWidth="borderWidth10"
				borderColor="colorBorder"
				padding="space20"
				marginBottom="space40"
			>
				<Button variant="link">
					<PlusIcon decorative size="sizeIcon80" />
				</Button>
				<Text paddingX="space40" paddingY="space40" as="p">
          Add a new document
				</Text>
			</Box>

			{/* Documents Table piece */}
			<DocumentsGrid />

			{/* Supporting information tables */}
			<Box display="flex" justifyContent="space-between" paddingTop="space80">
				{/* Versions table */}
				<Box width="45%">
					<Text padding="space40" as="h2">
            Versions
					</Text>
					<Table id="documentsTable">
						<THead>
							<Tr>
								<Th>Document</Th>
								<Th>Version Number</Th>
								<Th>Created By</Th>
								<Th>Created Date</Th>
							</Tr>
						</THead>
						<TBody>
							<Td>
								<Text as="p">A</Text>
							</Td>
							<Td>
								<Text as="p">1.1</Text>
							</Td>
							<Td>
								<Text as="p">Shaun</Text>
							</Td>
							<Td>
								<Text as="p">20/02/2022</Text>
							</Td>
						</TBody>
					</Table>
				</Box>
				<Box width="45%">
					{/* Access history table */}
					<Text padding="space40" as="h2">
            Access History
					</Text>
					<Table striped id="documentsTable">
						<THead>
							<Tr>
								<Th>User</Th>
								<Th>Version Number</Th>
								<Th>Access Date</Th>
							</Tr>
						</THead>
						<TBody>
							<Td>
								<Text as="p">Shaun</Text>
							</Td>
							<Td>
								<Text as="p">1.1</Text>
							</Td>
							<Td>
								<Text as="p">22/02/2022</Text>
							</Td>
						</TBody>
					</Table>
				</Box>
			</Box>

			{/* Buttons for cancel, delete, update new
        version and submit */}
			<Box display="flex" paddingTop="space100" justifyContent="space-between">
				<Box display="flex" justifyContent="space-between">
					<Box paddingRight="space80">
						<Button variant="secondary">Cancel</Button>
					</Box>
					<Box paddingRight="space80">
						<Button variant="destructive">Delete</Button>
					</Box>
					<Box>
						<Button variant="primary">Upload New Version</Button>
					</Box>
				</Box>
				<Box display="flex" justifyContent="right">
					<Button variant="primary">Submit</Button>
				</Box>
			</Box>
		</Box>
	);
};
