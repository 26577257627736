import { FC, useState } from "react";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { MoreIcon } from "@twilio-paste/icons/esm/MoreIcon";
import { MenuButton, MenuItem, Menu, useMenuState } from "@twilio-paste/menu";
import { Text } from "@twilio-paste/core/text";
import { Box } from "@twilio-paste/box";
import { LOGO, HEADERS_HEIGHT, LOGO_HEIGHT, Role } from "../../constants";
import { LogoutModal } from "../LogoutModal/LogoutModal";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { User } from "@ciptex/ccp";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";

export const Header: FC = () => {
	const menu = useMenuState();
	const navigate = useNavigate();
	const userMenu = useMenuState();
	const { appState } = useAppState();
	const [user, setUser] = useState<User>();
	const { getUser } = useCCPContext();

	return (
		<>
			<Box
				style={{ background: "rgb(21, 12, 57)" }}
				as="header"
				position="sticky"
				top="0"
				display="flex"
				justifyContent="flex-end"
				width="100%"
				height={HEADERS_HEIGHT}
				zIndex="zIndex10"
			>
				<Box width={"100%"} display="flex" color="colorTextBrandInverse">
					<Box
						paddingX="space60"
						paddingY="space50"
						marginTop="space30"
						display={["flex", "flex", "none"]}
					>
						<MenuButton {...menu} variant="reset" size="reset">
							<MoreIcon decorative={false} title="More options" />
						</MenuButton>
						<Menu {...menu} aria-label="Navigation">
							{appState.role === Role.admin && <>
								<MenuItem
									onClick={() => navigate("documents")}
									{...menu}
								>
									Documents
								</MenuItem>
								<MenuItem
									onClick={() => navigate("clients")}
									{...menu}
								>
									Clients
								</MenuItem>
								<MenuItem
									onClick={() => navigate("end-clients")}
									{...menu}
								>
									End Clients
								</MenuItem>
								<MenuItem
									onClick={() => navigate("campaigns")}
									{...menu}
								>
									Campaigns
								</MenuItem>
								<MenuItem
									onClick={() => navigate("outsourcers")}
									{...menu}
								>
									Outsourcers
								</MenuItem>
								<MenuItem
									onClick={() => navigate("complaints")}
									{...menu}
								>
									Complaints
								</MenuItem>
								<MenuItem
									onClick={() => navigate("client-reporting")}
									{...menu}
								>
									Client Reporting
								</MenuItem>
							</>}
							{appState.role === Role.outsourcer && <>
								<MenuItem
									onClick={() => navigate("documents")}
									{...menu}
								>
									Documents
								</MenuItem>
							</>}
							{appState.role === Role.client && <>
								<MenuItem
									onClick={() => navigate("complaints")}
									{...menu}
								>
									Complaints
								</MenuItem>
								<MenuItem
									onClick={() => navigate("client-reporting")}
									{...menu}
								>
									Client Reporting
								</MenuItem>
							</>}

						</Menu>
					</Box>

					<Box
						width="sizeSidebar"
						display="flex"
						alignItems="center"
						justifyContent="start"
						paddingLeft="space60"
					>
						<img src={LOGO} alt="CCP Logo" height={LOGO_HEIGHT} />
					</Box>

					<Box
						display="flex"
						flex="1"
						paddingX="space100"
						paddingY="space50"
						justifyContent="space-between"
					>
						<Box
							display="flex"
							justifyContent="space-between"
							flexDirection="row"
							width="100%"
							alignItems="center"
						>
							<Box display="flex" flexDirection="column">
								<Text
									as="h2"
									fontWeight="fontWeightBold"
									fontSize="fontSize60"
									color="colorTextBrandInverse"
									display={["none", "none", "flex"]}
								>
                  Service Management Portal
								</Text>
							</Box>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="right"
								color="colorText"
								columnGap="space60"
							>
								<MenuButton id="userMenu" {...userMenu} variant="secondary">
									<UserIcon decorative={true} />
									{appState.email}
									{appState.role === Role.client &&<ChevronDownIcon
										decorative={false}
										title="User menu down chevron icon"
									/>}
								</MenuButton>
								<Menu {...userMenu} aria-label="User menu actions">
									{/* TODO - need to point this to user profile page taking in ID,
									also need user to be a user dynamic value */}
									{appState.role === Role.client &&<MenuItem onClick={() => navigate("profile")} {...userMenu}>
                    User Profile
									</MenuItem>}
								</Menu>
								<LogoutModal />
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
