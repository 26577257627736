import { Box } from "@twilio-paste/box";
import { Label } from "@twilio-paste/label";
import { ChangeEvent, FC, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { Input } from "@twilio-paste/input";
// import { Radio, RadioGroup } from "@twilio-paste/radio-group";
import { Button } from "@twilio-paste/button";
import { FORM_BACKGROUND } from "../../constants";
import { User, Users, ValidationResponse } from "@ciptex/ccp";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Flex } from "@twilio-paste/core/flex";
import { Spinner } from "@twilio-paste/core/spinner";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { HelpText } from "@twilio-paste/core/help-text";
import { Separator } from "@twilio-paste/core/separator";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";

export const UserProfile: FC = () => {

	const [ userId, setUserId ] = useState<number>();
	const { appState } = useAppState();

	const [ passwordMatchExistingError, setPasswordMatchExistingError ] = useState<boolean>(false);
	const [ passwordMatchNewError, setPasswordMatchNewError ] = useState<boolean>(false);

	const [user, setUser] = useState<User>();
	const [loaded, setLoaded] = useState<boolean>();
	const { getUser, updateUser, listUsersEmailFilter, validateUserPassword, setUserPassword } = useCCPContext();

	const [pwds, setPwds] = useState<any>();

	const { toaster } = useToasterContext();

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setUser({
			...user,
			[target.name]: target.value
		});
	};

	const handlePwdChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setPwds({
			...pwds,
			[target.name]: target.value
		});
	};

	const handleSubmit = async (e: any) => {
		console.log(user);
		e.preventDefault();
		(async () => {
			try {
				updateUser(
					user?.userId ?? 0,
					user ? user : {}
				);
				setLoaded(true);
				toaster.push({
					message: "User profile updated",
					variant: "success",
					dismissAfter: 4000
				});
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "User profile could not be updated",
					variant: "error",
					dismissAfter: 4000
				});
			}

		})();
	};

	const handlePwdSubmit = async (e: any) => {
		setPasswordMatchExistingError(false);
		setPasswordMatchNewError(false);
		e.preventDefault();
		(async () => {
			try {
				// validate existing password
				const validationResponse: ValidationResponse = await validateUserPassword({ "username" : appState.email, "password" : pwds.currentPassword });

				if (!validationResponse.passwordMatch)
				{
					setPasswordMatchExistingError(true);
				}
				else
				{
					if(pwds.newPassword !== pwds.retypePassword)
					{
						setPasswordMatchNewError(true);
					}
					else
					{
						setUserPassword({ "userId" : user?.userId, "password" : pwds.newPassword });
						// All good, update the password!
						updateUser(
							user?.userId ?? 0,
							user ? user : {}
						);
						setLoaded(true);
						toaster.push({
							message: "Password updated",
							variant: "success",
							dismissAfter: 4000
						});
					}
				}



			} catch (error) {
				console.error(error);
				toaster.push({
					message: "User profile could not be updated",
					variant: "error",
					dismissAfter: 4000
				});
			}

		})();
	};

	useEffect(() => {

		(async () => {
			try {
				const users: Users = await listUsersEmailFilter(encodeURI(appState.email));
				setUser(users[0]);
				setUserId(users[0].userId);
				const data: User = await getUser(users[0].userId ?? 0);
				console.log(data);
				setUser(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space60"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
			width="100%"
		>

			<HeaderTitleText titleText="User Profile" />

			{/* User details change form */}
			{user && loaded ? (
				<Box>

					<SubtitleText subtitleText="Details" />

					<form onSubmit={(e) => handleSubmit(e)}>
						<Box display="flex" flexDirection="row" justifyContent="space-between" marginY="space60">
							<Box width="30%">
								<Label htmlFor="email" >Email</Label>
								<Input id="email" name="email" type="email" value={user.email} disabled ></Input>
							</Box>

							<Box width="30%">
								<Label htmlFor="phone">Phone</Label>
								<Input id="phone" name="phone" type="text" value={user.phone} onChange={handleChange} defaultValue={user.phone ?? ""}></Input>
							</Box>
							<Box display="flex" alignItems="flex-end">
								<Button variant="primary" type="submit">Change Details</Button>
							</Box>

						</Box>
					</form>

					<Separator orientation="horizontal" verticalSpacing="space200" />

					<SubtitleText subtitleText="Password Change" />

					<form onSubmit={(e) => handlePwdSubmit(e)}>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							marginBottom="space60"
						>
							<Box width="25%">
								<Label required htmlFor="currentPassword">Current Password</Label>
								<Input required onChange={handlePwdChange}
									id="currentPassword"
									name="currentPassword"
									type="password"
								></Input>
								{passwordMatchExistingError && <HelpText variant="error">Password does not match existing password</HelpText>}
							</Box>

							<Box width="25%">
								<Label required htmlFor="newPassword">New Password</Label>
								<Input required onChange={handlePwdChange}
									id="newPassword"
									name="newPassword"
									type="password"
								></Input>
								{passwordMatchNewError && <HelpText variant="error">New passwords do not match</HelpText>}
							</Box>

							<Box width="25%">
								<Label required htmlFor="retypePassword">Retype New Password</Label>
								<Input required onChange={handlePwdChange}
									id="retypePassword"
									name="retypePassword"
									type="password"
								></Input>
							</Box>
							<Box display="flex" alignItems="flex-end">
								<Button variant="primary" type="submit">Change Password</Button>
							</Box>
						</Box>

					</form>



				</Box>
			) : (
				<Flex vAlignContent="center" hAlignContent="center">
					<Spinner size="sizeIcon110" decorative={false} title="Loading" />
				</Flex>
			)}

		</Box>
	);
};
