import { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Loading } from "../components/Loading/Loading";
import { MainContainer } from "../components/MainContainer/MainContainer";
import { CCPProvider } from "../components/CCPProvider/CCPProvider";
import { ToasterProvider } from "../components/ToasterProvider/ToasterProvider";
import { LoginPage } from "../components/LoginPage/LoginPage";
import { ClientsTable } from "../components/Clients/ClientsTable";
import { CampaignsTable } from "../components/Campaigns/CampaignsTable";
import { EndClientsTable } from "../components/EndClients/EndClientsTable";
import { OutsourcersTable } from "../components/Outsourcers/OutsourcersTable";
import { EditClient } from "../components/Clients/EditClient";
import { EditEndClient } from "../components/EndClients/EditEndClient";
import { AddEndClient } from "../components/EndClients/AddEndClient";
import { ManageCampaign } from "../components/Campaigns/ManageCampaign";
import { ManageCampaignDocuments } from "../components/Campaigns/ManageCampaignDocuments";
import { ManageOutsourcer } from "../components/Outsourcers/ManageOutsourcer";
import { UserProfile } from "../components/UserProfile/UserProfile";
import { ManageDocuments } from "../components/Campaigns/ManageDocuments";
import { CampaignDocuments } from "../components/Documents/CampaignDocuments";
import { CampaignReference } from "../components/Documents/CampaignReference";
import { ComplaintsTable } from "../components/Complaints/ComplaintsTable";
import { useAppState } from "../hooks/useAppState/useAppState";
import { Role } from "../constants";
import { AddClient } from "../components/Clients/AddClient";
import { AddOutsourcer } from "../components/Outsourcers/AddOutsourcer";
import { AddCampaign } from "../components/Campaigns/AddCampaign";
import { NonAuthMainContainer } from "../components/NonAuthMainContainer/NonAuthMainContainer";
import { TwoFactor } from "../components/LoginPage/TwoFactor";
import { NonAuthDocumentPop } from "../components/NonAuthDocumentPop/NonAuthDocumentPop";

export const Router: FC = () => {
	const { appState } = useAppState();
	return (<Suspense fallback={<Loading />}>
		<BrowserRouter basename="/">
			<CCPProvider>
				<ToasterProvider>
					<Routes>
						<Route index element={<LoginPage />} />
						<Route path="login/verify" element={<TwoFactor />} />
						<Route path="/campaign/configs/:campaignConfigId/:outsourcerId/:sessionId/:token/" element={<NonAuthMainContainer />}>
							<Route path="view" element={<NonAuthDocumentPop />} />
						</Route>
						<Route path="/" element={<MainContainer />}>
							{appState.role === Role.admin && <>
								<Route path="clients" element={<ClientsTable />} />
								<Route path="clients/add" element={<AddClient />} />
								<Route path="clients/edit" element={<ClientsTable />} />
								<Route path="clients/edit/:clientId" element={<EditClient />} />
								<Route path="end-clients" element={<EndClientsTable />} />
								<Route path="end-clients/add" element={<AddEndClient />} />
								<Route path="end-clients/edit" element={<EndClientsTable />} />
								<Route path="end-clients/edit/:endClientId" element={<EditEndClient />} />
								<Route path="campaigns" element={<CampaignsTable />} />
								<Route path="campaigns/edit/:campaignId/documents/:campaignConfigId" element={<ManageCampaignDocuments />} />
								<Route path="campaigns/edit" element={<CampaignsTable />} />
								<Route path="campaigns/edit/:campaignId" element={<ManageCampaign />} />
								<Route path="campaigns/add" element={<AddCampaign />} />
								<Route path="outsourcers" element={<OutsourcersTable />} />
								<Route path="outsourcers/edit" element={<OutsourcersTable />} />
								<Route path="outsourcers/edit/:outsourcerId" element={<ManageOutsourcer />} />
								<Route path="outsourcers/add" element={<AddOutsourcer />} />
								<Route path="profile" element={<UserProfile />} />
								<Route path="manage-documents" element={<ManageDocuments />} />
								<Route path="documents" element={<CampaignDocuments />} />
								<Route path="documents/view/:campaignConfigId" element={<CampaignReference />} />
								<Route path="complaints" element={<ComplaintsTable />} /> </>}
							{appState.role === Role.client && <>
								<Route path="complaints" element={<ComplaintsTable />} />
								<Route path="profile" element={<UserProfile />} /></>}
							{appState.role === Role.outsourcer && <>
								<Route path="documents" element={<CampaignDocuments />} />
								<Route path="documents/view/:campaignConfigId" element={<CampaignReference />} /> </>}
							<Route path="*" element={<LoginPage />} />
						</Route>
					</Routes>
				</ToasterProvider>
			</ CCPProvider>
		</BrowserRouter>
	</Suspense>);
};