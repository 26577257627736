import { Box } from "@twilio-paste/box";
import { Flex } from "@twilio-paste/flex";
import { Heading } from "@twilio-paste/heading";
import { Input } from "@twilio-paste/input";
import { Label } from "@twilio-paste/label";
import { ChangeEvent, FC, useState, useEffect } from "react";
import { Select, Option } from "@twilio-paste/select";
import { TextArea } from "@twilio-paste/textarea";
import { Button } from "@twilio-paste/button";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate } from "react-router-dom";
import { Outsourcer, ClientCore } from "@ciptex/ccp";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { FORM_BACKGROUND } from "../../constants";

export const AddOutsourcer: FC = () => {
	const navigate = useNavigate();
	const [outsourcer, setOutsourcer] = useState<Outsourcer>();
	const { createOutsourcer, listClients } = useCCPContext();
	const [clients, setClients] = useState<ClientCore>();

	const { toaster } = useToasterContext();

	useEffect(() => {
		(async () => {
			try {
				const data: ClientCore = await listClients();
				setClients(data);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

		if(target.name === 'otherIdentityProvider')
		{
			setOutsourcer({
				...outsourcer,
				identityProvider: target.value
			});
		}
		else{
			setOutsourcer({
				...outsourcer,
				[target.name]: target.value
			});
		}
	};

	const handleTextChange = (e: any) => {
		setOutsourcer({
			...outsourcer,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		(async () => {
			try {
				await createOutsourcer(outsourcer ? outsourcer : {});
				toaster.push({
					message: "Outsourcer created",
					variant: "success",
					dismissAfter: 4000
				});
				navigate("/outsourcers");
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not create new outsourcer",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	};

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space40"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
		>
			<HeaderTitleText titleText="Add a New Outsourcer" />
			<Box width="100%">
				<form onSubmit={(e) => handleSubmit(e)}>
					<Flex paddingBottom="space40" wrap>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
							paddingBottom={["space40", "space40", "space0"]}
						>
							<Label required htmlFor="name">
								Name
							</Label>
							<Input
								required
								onChange={handleChange}
								name="name"
								id="name"
								type="text"
							></Input>
						</Box>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
							paddingBottom={["space40", "space40", "space0"]}
						>
							<Label required htmlFor="clientId">
								Client
							</Label>
							<Select
								required
								onChange={handleChange}
								name="clientId"
								id="clientId"
							>
								{clients
									? clients &&
									clients.map((client: any, index: number) => (
										<Option
											defaultValue="Choose an option"
											key={index}
											value={client.clientId}
										>
											{client.name}
										</Option>
									))
									: null!}
							</Select>
						</Box>
					</Flex>

					<Flex paddingBottom="space40" wrap>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
							paddingBottom={["space40", "space40", "space0"]}
						>
							<Label htmlFor="status">Status</Label>
							<Input
								disabled
								onChange={handleChange}
								name="status"
								id="status"
								type="text"
								value="New"
							></Input>
						</Box>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
						>
							<Label htmlFor="twilioAccountSid">Twilio Account SID</Label>
							<Input
								onChange={handleChange}
								name="twilioAccountSid"
								id="twilioAccountSid"
								type="text"
								defaultValue={outsourcer?.twilioAccountSid}
							></Input>
						</Box>
					</Flex>

					<Flex paddingBottom="space40" wrap>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
							paddingBottom={["space40", "space40", "space0"]}
						>
							<Label htmlFor="provider" required>
								Identity Provider
							</Label>
							<Select
								required
								onChange={handleChange}
								name="identityProvider"
								id="identityProvider"
							>
								<Select onChange={handleChange} name="identityProvider" id="identityProvider" >
									<Option value="Azure AD">Azure AD</Option>
									<Option value="Okta">Okta</Option>
									<Option value="Other">Other</Option>
								</Select>
							</Select>
						</Box>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
						>
							<Label htmlFor="otherIdentityProvider">Other Identity Provider</Label>
							<Input
								onChange={handleChange}
								name="otherIdentityProvider"
								id="otherIdentityProvider"
								type="text"
							></Input>
						</Box>
					</Flex>

					<Flex paddingBottom="space40" wrap>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
							paddingBottom={["space40", "space40", "space0"]}
						>
							<Label htmlFor="availableCapacityMin">
								Available Minimum Capacity
							</Label>
							<Input
								onChange={handleChange}
								name="availableCapacityMin"
								id="availableCapacityMin"
								type="number"
							></Input>
						</Box>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
							paddingBottom={["space40", "space40", "space0"]}
						>
							<Label htmlFor="availableCapacityMin">
								Available Maximum Capacity
							</Label>
							<Input
								onChange={handleChange}
								name="availableCapacityMax"
								id="availableCapacityMax"
								type="number"
							></Input>
						</Box>
						<Box
							width={["100%", "100%", "50%"]}
							paddingRight={["space0", "space0", "space140"]}
						>
							<Label htmlFor="agreementStartDate">Agreement Start Date</Label>
							<Input
								onChange={handleChange}
								name="agreementStartDate"
								id="agreementStartDate"
								type="date"
							></Input>
						</Box>
					</Flex>

					<Box
						borderRadius="borderRadius20"
						borderStyle="solid"
						borderWidth="borderWidth10"
						borderColor="colorBorder"
						padding="space60"
					>
						<Heading as="h3" variant="heading30">
							Primary Operational Contact
						</Heading>
						<Box
							display={["row", "row", "flex"]}
							justifyContent="space-between"
						>
							<Box paddingRight={["space0", "space0", "space100"]}>
								<Label htmlFor="primaryOpsContactName">Name</Label>
								<Input
									onChange={handleChange}
									name="primaryOpsContactName"
									id="primaryOpsContactName"
									type="text"
								></Input>
							</Box>
							<Box paddingRight={["space0", "space0", "space100"]}>
								<Label htmlFor="primaryOpsContactEmail">Email</Label>
								<Input
									onChange={handleChange}
									name="primaryOpsContactEmail"
									id="primaryOpsContactEmail"
									type="email"
								></Input>
							</Box>
							<Box>
								<Label htmlFor="primaryOpsContactPhone">Phone</Label>
								<Input
									onChange={handleChange}
									id="primaryOpsContactPhone"
									name="primaryOpsContactPhone"
									type="text"
								></Input>
							</Box>
						</Box>
					</Box>

					<Box
						borderRadius="borderRadius20"
						borderStyle="solid"
						borderWidth="borderWidth10"
						borderColor="colorBorder"
						padding="space60"
						marginTop="space80"
						marginBottom="space80"
					>
						<Heading as="h3" variant="heading30">
							Secondary Operational Contact
						</Heading>
						<Box
							display={["row", "row", "flex"]}
							justifyContent="space-between"
						>
							<Box paddingRight={["space0", "space0", "space100"]}>
								<Label htmlFor="secondaryOpsContactName">Name</Label>
								<Input
									onChange={handleChange}
									id="secondaryOpsContactName"
									name="secondaryOpsContactName"
									type="text"
								></Input>
							</Box>
							<Box paddingRight={["space0", "space0", "space100"]}>
								<Label htmlFor="secondaryOpsContactEmail">Email</Label>
								<Input
									onChange={handleChange}
									id="secondaryOpsContactEmail"
									name="secondaryOpsContactEmail"
									type="email"
								></Input>
							</Box>
							<Box>
								<Label htmlFor="secondaryOpsContactPhone">Phone</Label>
								<Input
									onChange={handleChange}
									id="secondaryOpsContactPhone"
									name="secondaryOpsContactPhone"
									type="tel"
								></Input>
							</Box>
						</Box>
					</Box>

					<Box
						borderRadius="borderRadius20"
						borderStyle="solid"
						borderWidth="borderWidth10"
						borderColor="colorBorder"
						padding="space60"
						marginTop="space80"
						marginBottom="space80"
					>
						<Heading as="h3" variant="heading30">
							Out of Hours Contact
						</Heading>
						<Box display="flex" justifyContent="space-between">
							<Box paddingRight="space100">
								<Label htmlFor="outOfHoursContactName">Name</Label>
								<Input
									onChange={handleChange}
									id="outOfHoursContactName"
									name="outOfHoursContactName"
									type="text"
								></Input>
							</Box>
							<Box paddingRight="space100">
								<Label htmlFor="outOfHoursContactEmail">Email</Label>
								<Input
									onChange={handleChange}
									id="outOfHoursContactEmail"
									name="outOfHoursContactEmail"
									type="email"
								></Input>
							</Box>
							<Box>
								<Label htmlFor="outOfHoursContactPhone">Phone</Label>
								<Input
									onChange={handleChange}
									id="outOfHoursContactPhone"
									name="outOfHoursContactPhone"
									type="text"
								></Input>
							</Box>
						</Box>
					</Box>

					<Box paddingBottom="space40">
						<Label htmlFor="escalationProcess">Escalation Process</Label>
						<TextArea
							onChange={(e) => {
								handleTextChange(e);
							}}
							placeholder="Add text"
							id="escalationProcess"
							name="escalationProcess"
						/>
					</Box>

					<Box paddingBottom="space40">
						<Label htmlFor="ccpComment">CCP Comment</Label>
						<TextArea
							onChange={(e) => {
								handleTextChange(e);
							}}
							placeholder="Add text"
							id="ccpComment"
							name="ccpComment"
						/>
					</Box>

					<Box
						display="flex"
						justifyContent="flex-start"
						paddingTop="space60"
						columnGap="space60"
					>
						<Button
							onClick={() => navigate("/outsourcers")}
							variant="secondary"
						>
							Cancel
						</Button>
						<Button type="submit" variant="primary">
							Submit
						</Button>
					</Box>
				</form>
			</Box>
		</Box>
	);
};
