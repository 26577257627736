import { FC, useEffect, useState } from "react";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { Complaints } from "@ciptex/ccp";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button } from "@twilio-paste/core";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon"
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";

export const ComplaintsTable: FC = () => {
	const [complaints, setComplaints] = useState<Complaints>();
	const [loaded, setLoaded] = useState<boolean>(false);

	const { listComplaints, updateComplaint } = useCCPContext();
	const { appState } = useAppState();
	const { toaster } = useToasterContext();

	// Array of length 50 rows, all unchecked
	const [checkedItems, setCheckedItems] = useState(
		[...new Array(50)].map(() => false)
	);

	const getDate = () => {
		const today = DateTime.local();
		const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
		return todayDate;
	}

	const exportTableData = () => {
		if (complaints) {
			const csvData = [
				[
					"Complaint ID",
					"Campaign",
					"Outsourcer",
					"Complaint Email",
					"Complaint Phone",
					"Complaint Body",
					"Complaint Date"
				],
				...complaints.map(
					({ complaintId, campaignName, outsourcerName, email, phone, body, createdDate }) => [
						complaintId,
						campaignName,
						outsourcerName,
						email,
						phone,
						body,
						createdDate
					]
				)
			];
			const csv = csvData.map((row) => row.join(",")).join("\n");
			const csvBlob = new Blob([csv], { type: "text/csv" });
			const csvUrl = URL.createObjectURL(csvBlob);
			const downloadLink = document.createElement("a");
			downloadLink.href = csvUrl;
			downloadLink.download = `complaints-${getDate()}.csv`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			/* If scope is ccp_client ONLY then set all complaints as isSeen = 1*/
			if( appState.role === "ccp_client") {
				for (const c of complaints)
				{
					const newComplaint = { ...c, isSeen : true }
					updateComplaint(newComplaint?.complaintId ?? 0, newComplaint);
				}
				toaster.push({
					message: "CSV downloaded and complaints marked as seen",
					variant: "success",
					dismissAfter: 4000
				});
			}

		}
	};

	const allChecked = checkedItems.every(Boolean);
	const indeterminate = checkedItems.some(Boolean) && !allChecked;

	useEffect(() => {
		(async () => {
			try {
				const data: Complaints = await listComplaints();
				setComplaints(data);
				if(data.length > 0)
				{
					setLoaded(true);
				}
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Complaints" />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Button variant="secondary" onClick={exportTableData} >
					Download CSV
					<DownloadIcon decorative={false} title="download csv icon" />
				</Button>
			</Box>
			<Table aria-label="Complaints table" striped>
				<THead>
					<Tr>
						<Th>
						</Th>
						<Th>Complaint ID</Th>
						<Th>Campaign</Th>
						<Th>Outsourcer</Th>
						<Th>Email</Th>
						<Th>Phone</Th>
						<Th>Complaint Body</Th>
						<Th>Created Date</Th>
					</Tr>
				</THead>
				<TBody>
					{complaints && loaded ? (
						complaints &&
							complaints.map((complaint: any, rowIndex: number) => (
								<Tr key={rowIndex}>
									<Td>
									</Td>
									<Td>{complaint.complaintId}</Td>
									<Td>{complaint.campaignName}</Td>
									<Td>{complaint.outsourcerName}</Td>
									<Td>{complaint.email}</Td>
									<Td>{complaint.phone}</Td>
									<Td>{complaint.body}</Td>
									<Td>{complaint.createdDate}</Td>
								</Tr>
							))
					) : (
						<TableSkeletonLoader numberOfTd={8} numberOfTr={1} />
					)}
				</TBody>
			</Table>
		</Box>
	);
};
