import { FC, useState, FormEvent, useEffect, ChangeEvent } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Box } from "@twilio-paste/core/box";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { HelpText } from "@twilio-paste/core/help-text";
import { Heading } from "@twilio-paste/core/heading";
import { Button } from "@twilio-paste/core/button";
import { Alert } from "@twilio-paste/core/alert";
import { Text } from "@twilio-paste/core/text";
import { UserAuth } from "@ciptex/ccp";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Role } from "../../constants";

export const LoginPage: FC = () => {
	const height = useHeight();
	const navigate = useNavigate();
	const [ searchParams ] = useSearchParams();
	const { signIn, token, appDispatch, appState } = useAppState();
	const { connect: ccpConnect, providerReady } = useCCPContext();
	const [form, setForm] = useState<UserAuth>({
		username: "",
		password: ""
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [alert, setAlert] = useState<boolean>(false);

	useEffect(() => {
		if(providerReady && appState.token && appState.token !== "" && appState.token !== null  )
		{
			if (appState.role === Role.admin){
				navigate(searchParams.get("redirect") || "clients", { replace: true });
			} else if (appState.role === Role.client){
				navigate(searchParams.get("redirect") || "login/verify", { replace: true });
			} else if (appState.role === Role.outsourcer){
				navigate(searchParams.get("redirect") || "documents", { replace: true });
			}
		}
	}, [ providerReady, appState.token ]);

	useEffect(() => {
		if (token) {
			appDispatch({ type: "set-token", token: token.token ? token.token : "" });
			appDispatch({ type: "set-role", role: token.role ? token.role : "" });
			appDispatch({ type: "set-email", email: token.email ? token.email : "" });
			ccpConnect(token.token ? token.token : "");
		}
	}, [ token ]);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setAlert(false);
		setIsLoading(true);

		try {
			await signIn(form.username ? form.username: "", form.password ? form.password: "");
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setAlert(true);
		}
	};

	const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [name]: value });
	};

	return (
		<Box height={height} display="flex" flexDirection="column" flex={1} alignContent="center" justifyContent="center" alignItems="center" justifyItems="center">
			<Box borderColor="colorBorder" borderWidth="borderWidth10" borderStyle="solid" width={["100%", "30rem", "30rem"]} borderRadius="borderRadius30" padding="space100">
				<form onSubmit={onSubmit}>
					{alert && <Box marginY="space30">
						<Alert variant="error">
							<Text as="span"><strong>Wrong username or password.</strong> To reset your password contact your IT Team</Text>
						</Alert>
					</Box>}
					<Heading as="h2" variant="heading10">Login</Heading>
					<Box marginBottom="space60">
						<Label htmlFor="username" required>Email</Label>
						<Input
							aria-describedby="username_help_text"
							id="username"
							name="username"
							autoComplete="username"
							onChange={onChange}
							value={form.username}
							type="text"
							placeholder="username"
							required
						/>
						<HelpText id="username_help_text">Please enter a valid username.</HelpText>
					</Box>
					<Box>
						<Label htmlFor="password" required>Password</Label>
						<Input
							aria-describedby="password_help_text"
							id="password"
							name="password"
							onChange={onChange}
							value={form.password}
							autoComplete="current-password"
							type="password"
							required
						/>
						<HelpText id="password_help_text">Please enter a valid password.</HelpText>
					</Box>
					<Box marginTop="space60" float="right">
						<Button variant="primary" type="submit" loading={isLoading}>Login</Button>


					</Box>
				</form>
			</Box>
		</Box>);
};

