import { FC } from "react";
import { Button } from "@twilio-paste/core/button";
import { Stack } from "@twilio-paste/core/stack";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Role } from "../../constants";

export const SidenavLinks: FC = () => {
	const { appState } = useAppState();
	const navigate = useNavigate();
	return (
		<>
			{appState.role === Role.admin && <>
				<Stack spacing="space80" orientation="vertical">
					{/*<Button onClick={() => navigate("documents")} variant="reset">
					Campaign Documents
			</Button>*/}
					<Button onClick={() => navigate("clients")} variant="reset">
					Clients
					</Button>
					<Button onClick={() => navigate("end-clients")} variant="reset">
					End Clients
					</Button>
					<Button onClick={() => navigate("campaigns")} variant="reset">
					Campaigns
					</Button>
					<Button onClick={() => navigate("outsourcers")} variant="reset">
					Outsourcers
					</Button>
					{/* <Button onClick={() => navigate("client-reporting")} variant="reset">
					Clients Reporting
					</Button> */}
					<Button onClick={() => navigate("complaints")} variant="reset">
					Complaints
					</Button>
				</Stack>
			</>}
			{appState.role === Role.client && <>
				<Stack spacing="space80" orientation="vertical">
					{/* <Button onClick={() => navigate("client-reporting")} variant="reset">
					Clients Reporting
					</Button> */}
					<Button onClick={() => navigate("complaints")} variant="reset">
					Complaints
					</Button>
				</Stack>
			</>}
			{appState.role === Role.outsourcer && <>
				<Stack spacing="space80" orientation="vertical">
					<Button onClick={() => navigate("documents")} variant="reset">
					Campaign Documents
					</Button>
				</Stack>
			</>}
		</>
	);
};
