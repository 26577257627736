import { Box } from "@twilio-paste/box";
import { Flex } from "@twilio-paste/flex";
import { Heading } from "@twilio-paste/heading";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button } from "@twilio-paste/button";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/table";
import { Campaign, CampaignConfigs, ClientConfig } from "@ciptex/ccp";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate, useParams } from "react-router-dom";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { FORM_BACKGROUND } from "../../constants";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Spinner } from "@twilio-paste/core/spinner";
import { Stepper } from "../Stepper/Stepper";
import { SLATable } from "../SLATable/SLATable";
import { Select } from "@twilio-paste/core/select";
import { Option, Checkbox, CheckboxGroup } from "@twilio-paste/core";
import { HelpText } from "@twilio-paste/core/help-text";


export const ManageCampaign: FC<Campaign> = () => {
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const [campaign, setCampaign] = useState<Campaign>();

	// CampaignConfig table data: ***
	const [campaignConfigs, setCampaignConfigs] = useState<any[]>();
	const [campaignConfigIdsToDelete, setCampaignConfigIdsToDelete] = useState<number[]>();
	const [availableLanguages, setAvailableLanguages]= useState<any>();
	const [availableOutsourcers, setAvailableOutsourcers]= useState<any>();
	const [availablePhoneNumbers, setAvailablePhoneNumbers]= useState<{value : number | undefined, label : string | undefined}[]>();
	//* ***

	const [loaded, setLoaded] = useState<boolean>();
	const [tableError, setTableError] = useState<string>();
	const {
		getCampaign,
		updateCampaign,
		listCampaignCampaignConfigs,
		getClientConfig,
		createCampaignConfig,
		updateCampaignConfig,
		deleteCampaignConfig
	} = useCCPContext();

	const { toaster } = useToasterContext();

	const onCheckboxChangeLanguage = ({ target }: any, index: number, rowIndex : number) => {
		console.log('In checkbox change');
		const [idn, name] = target.name.split("#");
		const [idv, value] = target.value.split("#");
		if (target.checked) {
			if (campaignConfigs)
			{
				const cc : any = campaignConfigs.filter(
					(i) => i.campaignConfigId === index
				)[0];
				const l = cc.chosenLanguages;
				l.push(parseInt(value ? value : ""))
				const campaignConfigsNew = campaignConfigs.map((x) => x)
				const newCampaignConfig =  {
					...cc,
					['chosenLanguages']: l
				}
				campaignConfigsNew.splice(rowIndex, 1, newCampaignConfig); 
				setCampaignConfigs(campaignConfigsNew);
			}

		} else {
			if (campaignConfigs)
			{
				const l = campaignConfigs[rowIndex].chosenLanguages.filter(
					(i : number) => i !== parseInt(value ? value : "")
				);

				const campaignConfigsNew = campaignConfigs.map((x) => x)
				const newCampaignConfig =  {
					...campaignConfigs[rowIndex],
					['chosenLanguages']: l
				}
				campaignConfigsNew.splice(rowIndex, 1, newCampaignConfig);
				setCampaignConfigs(campaignConfigsNew);
			}
		}
	};

	const onCheckboxChangeOutsourcer = ({ target }: any, index: number, rowIndex : number) => {
		const [idn, name] = target.name.split("#");
		const [idv, value] = target.value.split("#");
		if (target.checked) {
			if (campaignConfigs)
			{
				const cc : any = campaignConfigs.filter(
					(i) => i.campaignConfigId === index
				)[0];
				const l = cc.chosenOutsourcers;
				l.push(parseInt(value ? value : ""))

				const campaignConfigsNew = campaignConfigs.map((x) => x)
				const newCampaignConfig =  {
					...cc,
					['chosenOutsourcers']: [... new Set(l)]
				}
				console.log(newCampaignConfig);
				campaignConfigsNew.splice(rowIndex, 1, newCampaignConfig); 
				setCampaignConfigs(campaignConfigsNew);
			}

		} else {
			if (campaignConfigs)
			{
				const l = campaignConfigs[rowIndex].chosenOutsourcers.filter(
					(i : number) => i !== parseInt(value ? value : "")
				);

				const campaignConfigsNew = campaignConfigs.map((x) => x)
				const newCampaignConfig =  {
					...campaignConfigs[rowIndex],
					['chosenOutsourcers']: [... new Set(l)]
				}
				campaignConfigsNew.splice(rowIndex, 1, newCampaignConfig);
				setCampaignConfigs(campaignConfigsNew);
			}
		}
	};

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setCampaign({
			...campaign,
			[target.name]: target.value
		});
	};


	const removeRow = (campaignConfigId: number) => {
		if (campaignConfigs) {
			const cc = campaignConfigs.filter(
				(i) => i.campaignConfigId !== campaignConfigId
			);
			setCampaignConfigs(cc
			);
			// eslint-disable-next-line camelcase
			const cc_d = campaignConfigIdsToDelete ?? [];
			// eslint-disable-next-line camelcase
			cc_d.push(campaignConfigId);
			setCampaignConfigIdsToDelete(cc_d);
		}
	};

	const addRow = () => {
		if (campaignConfigs)
		{
			const cc = campaignConfigs.map((x) => x)
			cc.push({ number : "", languages : [], outsourcers : [], chosenLanguages : [], chosenOutsourcers : [], 
				campaignId : campaign?.campaignId, campaignConfigId : - Math.floor(Math.random() * 1000 + 1) })
			console.log("cc", cc);
			setCampaignConfigs(cc)
		}
		else
		{
			const cc = []
			cc.push({ number : "", languages : [], outsourcers : [], chosenLanguages : [], chosenOutsourcers : [], 
				campaignId : campaign?.campaignId, campaignConfigId : - Math.floor(Math.random() * 1000 + 1) })
			console.log("cc", cc);
			setCampaignConfigs(cc)
		}

	};


	const handleCampaignConfigChange = (index: number, e: any) => {
		if (campaignConfigs) {
			const newCCs: any = campaignConfigs;
			newCCs[index][e.target.name] = e.target.value;
			setCampaignConfigs(newCCs)
		} else {
			const newCCs: any = {};
			newCCs[index][e.target.name] = e.target.value;
			setCampaignConfigs(newCCs)
		}
	};




	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setTableError(undefined);
		(async () => {
			try {
				// validate campaignconfig rows
				const phoneNos : string[] = [];
				let isError = false;
				for (const cc of campaignConfigs ?? [])
				{
					if(phoneNos.includes( cc.number ))
					{

						setTableError("Phone numbers must be unique");
						isError = true;
						break;
					}
					else
					{
						phoneNos.push(cc.number)
					}
				}

				if (! isError)
				{
					// update campaign
					await updateCampaign(
						parseInt(campaignId ? campaignId : ""),
						campaign ? campaign : {}
					);

					toaster.push({
						message: "Campaign details updated",
						variant: "success",
						dismissAfter: 4000
					});

					try
					{
						// update campaignconfigs
						for (const cc of campaignConfigs ?? [])
						{
							let langs = [];
							for (const l of cc.chosenLanguages)
							{
								langs.push({languageId : l})
							}

							let outs : any = [];
							for (const l of cc.chosenOutsourcers)
							{
								outs.push({outsourcerId : l})
							}

							console.log('outs', outs)

							const newCampaignConfig =  {
								...cc,
								['languages']: langs,
								['outsourcers'] : outs
							}
							console.log('newCampaignConfig',newCampaignConfig)

							if (cc.campaignConfigId < 0)
							{
								// create new campaignconfig
								createCampaignConfig(newCampaignConfig);
							}
							else
							{
								// update existing campaignconfig
								updateCampaignConfig(cc.campaignConfigId, newCampaignConfig);
							}
						}
						// delete any removed campaignconfigs
						for (const c of campaignConfigIdsToDelete ?? [])
						{
							deleteCampaignConfig(c);
						}

						toaster.push({
							message: "Campaign configurations updated",
							variant: "success",
							dismissAfter: 4000
						});
						setLoaded(true);
					}
					catch (error) {
						console.error(error);
						toaster.push({
							message: "Campaign configurations could not be updated",
							variant: "error",
							dismissAfter: 4000
						});
					}
					// redirect
					navigate("/campaigns");
				}

			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Campaign details could not be updated",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	};


	useEffect(() => {
		if (!campaignId) {
			navigate("/campaigns");
			return;
		}
		(async () => {
			try {
				const cam: Campaign = await getCampaign(parseInt(campaignId));
				setCampaign(cam);

				// get campaignconfigs for the campaignid
				const campConfs: CampaignConfigs = await listCampaignCampaignConfigs(
					parseInt(campaignId ? campaignId: "") ?? 0
				);

				

				let noCCs = false;
				
				if((campConfs as any).error_message)
				{
					noCCs = true;
				}
				else{
					setCampaignConfigs(campConfs);
				}

				// get client config for the available items in the campaignconfig table components
				const clientConfigs : ClientConfig = await getClientConfig(cam?.clientId ?? 0);


				// get the right end client here as the campaign is against an end client
				for (const cc of clientConfigs)
				{
					if (cc.endClientId === cam.endClientId)
					{
						setAvailableLanguages(cc.languages);
						setAvailableOutsourcers(cc.outsourcers);

						const pnForDisplay = []

						for (const pn of cc.phoneNumbers ?? [])
						{
							pnForDisplay.push({ label : pn.label, value : pn.value })
						}

						setAvailablePhoneNumbers(pnForDisplay);

						const localCCArray = [];

						if(!noCCs)
						{
							for(const c of campConfs) {

								const langs : number[] = [];
								for (const l of c.languages ?? [])
								{
									langs.push(l.languageId ?? 0);
								}
	
								const outs : number[] = [];
								for (const l of c.outsourcers ?? [])
								{
									outs.push(l.outsourcerId ?? 0);
								}
	
								const cc1 = { ...c, chosenLanguages : langs, chosenOutsourcers : outs };
	
								console.log('cc1', cc1);
	
								localCCArray.push(cc1);
							}
						}
						

						setCampaignConfigs(localCCArray);
						setLoaded(true);
						console.log(localCCArray);
					}
				}




			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve campaign config data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Manage Campaign" />

			<Box display="flex" justifyContent="center" paddingBottom="space60">
				<Stepper campaignId={parseInt(campaignId ?? "0")} endClientId={0} />
			</Box>

			{/* Main box for editable data and SLA table */}
			<Box display={["block", "block", "flex"]}>
				{/* All the left hand side editable data piece */}
				<Box
					display="flex"
					flexWrap="wrap"
					flexDirection="column"
					style={{ background: FORM_BACKGROUND }}
					padding="space60"
					borderRadius="borderRadius30"
					marginBottom="space60"
					marginTop="space40"
					borderStyle="solid"
					borderWidth="borderWidth10"
					borderColor="colorBorder"
					width={["100%", "100%", "50%"]}
				>
					<Heading as="h2" variant="heading20">
						Details
					</Heading>
					{campaign && loaded ? (
						<form onSubmit={(e) => handleSubmit(e)}>
							<Box
								display="flex"
								width="100%"
								justifyContent="space-between"
								paddingBottom="space60"
							>
								<Box width="40%">
									<Label htmlFor="name">Name</Label>
									<Input
										onChange={handleChange}
										id="name"
										name="name"
										type="text"
										defaultValue={campaign.name}
									/>
								</Box>

								<Box width="40%">
									<Label htmlFor="reference">Reference</Label>
									<Input
										onChange={handleChange}
										id="reference"
										name="reference"
										type="text"
										defaultValue={campaign.reference}
									/>
								</Box>
							</Box>

							<Box display="flex" width="100%" justifyContent="space-between">
								<Box width="40%">
									<Label htmlFor="client">Client</Label>
									<Input
										disabled
										type="text"
										name="client"
										id="client"
										defaultValue={campaign.client}
									/>
								</Box>



								<Box width="40%">
									<Label htmlFor="invokeTime">Invoke Time</Label>
									<Input
										disabled
										name="invokeTime"
										id="invokeTime"
										defaultValue={campaign.invokeTime}
										type="text"
									/>
								</Box>
							</Box>

							{/* Campaigns addition and table piece */}
							<Box
								display="flex"
								justifyContent="space-between"
								marginY="space60"
								alignItems="center"
							>
								<Heading as="h3" variant="heading30">
									Configurations
								</Heading>
								<Box>
									<Button variant="primary" onClick={() => addRow()}>Add A New Configuration</Button>
								</Box>
							</Box>
							{tableError && <HelpText variant="error">{tableError}</HelpText>}
							<Table id="campaignconfigstable">
								<THead>
									<Tr>
										<Th textAlign="center">Phone Number</Th>
										<Th textAlign="center">Languages</Th>
										<Th textAlign="center">Outsourcer</Th>
										<Th textAlign="right">Documents</Th>
										<Th textAlign="right">Actions</Th>
									</Tr>
								</THead>
								<TBody>
									{campaignConfigs && loaded
										? campaignConfigs.map(
											(campaignConfig: any, index: number) => (
												<Tr key={campaignConfig.campaignConfigId}>
													<Td>
														<Select onChange={(e) =>
															handleCampaignConfigChange(index, e)
														}
														name="number" id="number" defaultValue = {campaignConfig.number}
														>
															<Option key={index} value={campaignConfig.number} >{campaignConfig.number}</Option>
															{ loaded ? (

																(availablePhoneNumbers??[]).map((phoneNumber: any, index: number ) => (

																	<Option key={index} value={phoneNumber.label} >{phoneNumber.label}</Option>

																))) : null! }
														</Select>
													</Td>
													<Td>
														<CheckboxGroup
															name="langscboxgroup"
															legend=""
														>
															{ loaded ? (
																<CheckboxGroup name="langs" legend="" helpText="">
																	{availableLanguages &&
																			availableLanguages.map((l: { value?: number ; label?: string }) => (
																				<Checkbox
																					key={`${campaignConfig.campaignConfigId}#${l.value}`}
																					id={`${campaignConfig.campaignConfigId}#${l.label}`}
																					defaultChecked={campaignConfig.chosenLanguages.includes(l.value as any)}
																					value={`${campaignConfig.campaignConfigId}#${l.value}`}
																					name={`${campaignConfig.campaignConfigId}#${l.label}`}
																					onClick={(e) => onCheckboxChangeLanguage(e, campaignConfig.campaignConfigId, index)}>
																					{l.label || "error"}
																				</Checkbox>
																			))}
																</CheckboxGroup>

															) : null! }
														</CheckboxGroup>
													</Td>
													<Td>
														
													<CheckboxGroup
															name="outscboxgroup"
															legend=""
														>
															{ loaded ? (
																<CheckboxGroup name="outs" legend="" helpText="">
																	{availableOutsourcers &&
																			availableOutsourcers.map((l: { value?: number ; label?: string }) => (
																				<Checkbox
																					key={`${campaignConfig.campaignConfigId}#${l.value}`}
																					id={`${campaignConfig.campaignConfigId}#${l.label}`}
																					defaultChecked={campaignConfig.chosenOutsourcers.includes(l.value as any)}
																					value={`${campaignConfig.campaignConfigId}#${l.value}`}
																					name={`${campaignConfig.campaignConfigId}#${l.label}`}
																					onClick={(e) => onCheckboxChangeOutsourcer(e, campaignConfig.campaignConfigId, index)}>
																					{l.label || "error"}
																				</Checkbox>
																			))}
																</CheckboxGroup>

															) : null! }
														</CheckboxGroup>
													</Td>
													<Td textAlign="right">
														{loaded ? (

															<Button
																variant="link"
																onClick={() => navigate(`documents/${campaignConfig.campaignConfigId}`)}
															>
															View / Manage Documents
															</Button>

														) : (
															""
														)}
													</Td>
													<Td textAlign="right">
														<Box>
															<Button
																variant="destructive"
																onClick={() =>
																	removeRow(campaignConfig.campaignConfigId)
																}
															>
                                    Remove Config
															</Button>
														</Box>
													</Td>
												</Tr>
											)
										)
										: ""}
								</TBody>
							</Table>


							{/* Button container and buttons */}
							<Box
								display="flex"
								justifyContent="space-between"
								paddingTop="space60"
							>
								<Button
									variant="secondary"
									onClick={() => navigate("/campaigns")}
								>
									Cancel
								</Button>
								<Button type="submit" variant="primary">
									Submit
								</Button>
							</Box>
						</form>
					) : (
						<Flex vAlignContent="center" hAlignContent="center">
							<Spinner size="sizeIcon110" decorative={false} title="Loading" />
						</Flex>
					)}
				</Box>

				{/* SLA table to right of data piece */}
				<Box display="flex" width={["100%", "100%", "50%"]}>
					<SLATable campaignId={parseInt(campaignId ?? "0")} endClientId={0} />
				</Box>
			</Box>
		</Box>
	);
};
