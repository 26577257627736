import { FC, useEffect, useState } from "react";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { EndClientCore } from "@ciptex/ccp";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button } from "@twilio-paste/core/button";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";

export const EndClientsTable: FC = () => {
	const navigate = useNavigate();

	const [endClients, setEndClients] = useState<EndClientCore>();
	const [loaded, setLoaded] = useState<boolean>();

	const { listEndClients, deleteEndClient } = useCCPContext();
	const { toaster } = useToasterContext();

	useEffect (() => {
		(async () => {
			try {
				const data: EndClientCore = await listEndClients();
				setEndClients(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, [])

	const handleDelete = async (endClientId: number) => {
		if (endClients){
			try {
				await deleteEndClient(endClientId);
				toaster.push({
					message: "End client has been removed",
					variant: "success",
					dismissAfter: 4000
				});
				setEndClients(endClients.filter((endClient) => endClient.endClientId !== endClientId));
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "End client could not be removed",
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="End Clients" />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Button variant="primary" onClick={() => navigate("add") }><PlusIcon decorative={false} title="Add new end client" />Add New End Client</Button>
			</Box>
			<Table aria-label="End Clients table" striped>
				<THead >
					<Tr>
						<Th>End Client ID</Th>
						<Th>Client</Th>
						<Th>Organisation</Th>
						<Th>Status</Th>
						<Th textAlign="right">Actions</Th>
					</Tr>
				</THead>
				<TBody>
					{endClients && loaded ? (
						endClients && endClients.map((endClients: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td >
									{endClients.endClientId}
								</Td>
								<Td >
									{endClients.client}
								</Td>
								<Td >
									{endClients.name}
								</Td>
								<Td >
									{endClients.status}
								</Td>
								<Td >
									<Box display="flex" justifyContent="flex-end" flexDirection="row" columnGap="space60">
										<Button variant="secondary" onClick={() => navigate(`edit/${endClients.endClientId}`)}><EditIcon decorative={false} title="Edit end client" /></Button>
										<Button variant="destructive" onClick={() => handleDelete(endClients.endClientId)}><DeleteIcon decorative={false} title="Delete end client" /></Button>
									</Box>
								</Td>
							</Tr>
						))): <TableSkeletonLoader numberOfTd={5} numberOfTr={1} />}
				</TBody>
			</Table>
		</Box>
	);
};
