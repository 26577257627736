import { Box } from "@twilio-paste/box";
import { Heading } from "@twilio-paste/heading";
import { Input } from "@twilio-paste/input";
import { Label } from "@twilio-paste/label";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { FileIcon } from "@twilio-paste/icons/esm/FileIcon";
import { Button } from "@twilio-paste/button";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/table";
import { ClientCore, EndClient } from "@ciptex/ccp";
import { useCCPContext } from "../../hooks/useCCPContext/useCCPContext";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNavigate } from "react-router-dom";
import { FORM_BACKGROUND } from "../../constants";
import { useToasterContext } from "../../hooks/useToasterContext";
import { HelpText } from "@twilio-paste/core/help-text";
import { Select } from "@twilio-paste/core/select";
import { Option } from "@twilio-paste/core";

export const AddEndClient: FC = () => {
	const navigate = useNavigate();
	const [endClient, setEndClient] = useState<EndClient>();
	const [loaded, setLoaded] = useState<boolean>();
	const [clients, setClients] = useState<ClientCore>();
	const { createEndClient, listClients } = useCCPContext();

	const [ clientIdError, setClientIdError ] = useState<boolean>(false);

	const { toaster } = useToasterContext();

	useEffect (() => {
		(async () => {
			try {
				const data: ClientCore = await listClients();
				setClients(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setEndClient({
			...endClient,
			[target.name]: target.value
		});
	};


	const handleContractChange = (index: number, e: any) => {
		if (endClient?.contracts) {
			const newContracts: any = [...endClient.contracts];
			newContracts[index][e.target.name] = e.target.value;
			setEndClient({
				...endClient,
				contracts: newContracts
			});
		} else {
			const newContracts: any = [];
			newContracts[index][e.target.name] = e.target.value;
			setEndClient({
				...endClient,
				contracts: newContracts
			});
		}
	};

	const addRow = () => {
		if (endClient?.contracts) {
			setEndClient({
				...endClient,
				contracts: [
					...endClient.contracts,
					{
						endClientContractId: Math.floor(Math.random() * 1000 + 1),
						language: "",
						invokeTime: "",
						NumberOfSeats: 0
					}
				]
			});
		} else {
			setEndClient({
				...endClient,
				contracts: [
					{
						endClientContractId: Math.floor(Math.random() * 1000 + 1),
						language: "",
						invokeTime: "",
						NumberOfSeats: 0
					}
				]
			});
		}
	};

	const removeRow = (endClientContractId: number) => {
		if (endClient?.contracts) {
			setEndClient({
				...endClient,
				contracts: endClient.contracts.filter(
					(i) => i.endClientContractId !== endClientContractId
				)
			});
		}
	};

	const handleSubmit = async (e: any) => {
		setClientIdError(false);
		e.preventDefault();
		(async () => {
			try {
				if(endClient?.clientId === undefined || endClient?.clientId === 0)
				{
					setClientIdError(true);
				}
				else
				{
					await createEndClient(endClient ? endClient : {});
					setLoaded(true);
					toaster.push({
						message: "End client created",
						variant: "success",
						dismissAfter: 4000
					});
					navigate("/end-clients");
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not create new end client",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Add New End Client" />

			{/* Main box for editable data and SLA table */}
			<Box>
				{/* All the left hand side editable data piece */}
				<Box
					display="flex"
					flexWrap="wrap"
					flexDirection="column"
					style={{ background: FORM_BACKGROUND }}
					padding="space60"
					borderRadius="borderRadius30"
					marginBottom="space60"
					marginTop="space40"
					borderStyle="solid"
					borderWidth="borderWidth10"
					borderColor="colorBorder"
				>
					<form onSubmit={(e) => handleSubmit(e)}>
						<Box
							display="flex"
							width="100%"
							justifyContent="space-between"
							paddingBottom="space40"
							style={{ background: FORM_BACKGROUND }}
						>
							{/* TODO - turn in to a select - grab clients using GET list clients */}
							<Box width="40%">
								<Label required htmlFor="clientId">Client</Label>
								<Select onChange={handleChange} name="clientId" id="clientId">
									<Option defaultValue="Choose an option" value={"0"} >Select a Client</Option>
									{clients && loaded ? (

										clients && clients.map((client: any, index: number ) => (

											<Option defaultValue="Choose an option" key={index} value={client.clientId} >{client.name}</Option>

										))) : null! }
								</Select>
								{clientIdError && <HelpText variant="error">Cannot submit without a Client</HelpText>}
							</Box>

							<Box width="40%">
								<Label htmlFor="name">Organisation Name</Label>
								<Input
									onChange={handleChange}
									id="name"
									name="name"
									type="text"
								/>
							</Box>
						</Box>

						<Box
							borderRadius="borderRadius20"
							borderStyle="solid"
							borderWidth="borderWidth10"
							borderColor="colorBorder"
							padding="space60"
						>
							<Heading as="h2" variant="heading20">
                Primary Operational Contact
							</Heading>
							<Box
								display={["row", "row", "flex"]}
								justifyContent="space-between"
							>
								<Box paddingRight={["space0", "space0", "space100"]}>
									<Label htmlFor="primaryOpsContactName">Name</Label>
									<Input
										onChange={handleChange}
										name="primaryOpsContactName"
										id="primaryOpsContactName"
										type="text"
									/>
								</Box>
								<Box paddingRight={["space0", "space0", "space100"]}>
									<Label htmlFor="primaryOpsContactEmail">Email</Label>
									<Input
										onChange={handleChange}
										name="primaryOpsContactEmail"
										id="primaryOpsContactEmail"
										type="email"
									/>
								</Box>
								<Box>
									<Label htmlFor="primaryOpsContactPhone">Phone</Label>
									<Input
										onChange={handleChange}
										id="primaryOpsContactPhone"
										name="primaryOpsContactPhone"
										type="text"
									/>
								</Box>
							</Box>
						</Box>

						<Box
							borderRadius="borderRadius20"
							borderStyle="solid"
							borderWidth="borderWidth10"
							borderColor="colorBorder"
							padding="space60"
							marginTop="space80"
							marginBottom="space80"
						>
							<Heading as="h2" variant="heading20">
                Secondary Operational Contact
							</Heading>
							<Box
								display={["row", "row", "flex"]}
								justifyContent="space-between"
							>
								<Box paddingRight={["space0", "space0", "space100"]}>
									<Label htmlFor="secondaryOpsContactName">Name</Label>
									<Input
										onChange={handleChange}
										id="secondaryOpsContactName"
										name="secondaryOpsContactName"
										type="text"
									/>
								</Box>
								<Box paddingRight={["space0", "space0", "space100"]}>
									<Label htmlFor="secondaryOpsContactEmail">Email</Label>
									<Input
										onChange={handleChange}
										id="secondaryOpsContactEmail"
										name="secondaryOpsContactEmail"
										type="email"
									/>
								</Box>
								<Box>
									<Label htmlFor="secondaryOpsContactPhone">Phone</Label>
									<Input
										onChange={handleChange}
										id="secondaryOpsContactPhone"
										name="secondaryOpsContactPhone"
										type="text"
									/>
								</Box>
							</Box>
						</Box>

						{/* SOW UI pieces, wrapped in own box to fit flow */}
						<Box
							display="flex"
							width="100%"
							justifyContent="space-between"
							paddingBottom="space40"
						>
							<Box width="30%">
								<Label htmlFor="sowTerm">SOW Term</Label>
								<Input
									onChange={handleChange}
									name="sowTerm"
									id="sowTerm"
									type="text"
								/>
							</Box>

							<Box width="30%">
								<Label htmlFor="sowStartDate">SOW Start Date</Label>
								<Input
									onChange={handleChange}
									name="sowStartDate"
									id="sowStartDate"
									type="date"
								/>
							</Box>

							<Box width="30%">
								<Label htmlFor="sowEndDate">SOW End Date</Label>
								<Input
									onChange={handleChange}
									name="sowEndDate"
									id="sowEndDate"
									type="date"
								/>
							</Box>
						</Box>

						{/* Test Date UI, wrapped in own box for flow */}
						<Box
							display="flex"
							width="100%"
							justifyContent="space-between"
							paddingBottom="space40"
						>
							<Box width="30%">
								<Label htmlFor="firstTestDate">First Test Date</Label>
								<Input
									onChange={handleChange}
									name="firstTestDate"
									id="firstTestDate"
									type="date"
								/>
							</Box>
							<Box width="30%">
								<Label htmlFor="nextTestDate">Next Test Date</Label>
								<Input
									onChange={handleChange}
									name="nextTestDate"
									id="nextTestDate"
									type="date"
								/>
							</Box>
							<Box width="30%">
								<Label htmlFor="lastTestDate">Last Test Date</Label>
								<Input
									onChange={handleChange}
									name="lastTestDate"
									id="lastTestDate"
									type="date"
								/>
							</Box>
						</Box>

						{/* Playbook and Call Distribution UI pieces */}
						{/* Distribution plan box and content */}
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							columnGap="space60"
							marginY="space60"
						>
							<Box display="flex" flexDirection="column" width="100%">
								<Box
									width="100%"
									borderRadius="borderRadius20"
									borderStyle="solid"
									borderWidth="borderWidth10"
									borderColor="colorBorder"
									id="playbook"
								>
									<Box margin="space60">
										<Heading as="h3" variant="heading30">
                      Playbook
										</Heading>
									</Box>
									<Box
										display="flex"
										flexDirection="row"
										columnGap="space60"
										margin="space60"
										alignItems="center"
									>
										<Button variant="secondary" size="icon">
											<FileIcon
												decorative={false}
												title="View current playbook"
											/>
										</Button>
										<Box width="100%">
											<Input
												type="text"
												placeholder="Enter the URL"
												name="playbookDocURL"
												id="playbookDocURL"
												onChange={handleChange}
											/>
										</Box>
									</Box>
								</Box>
							</Box>
							{/* Distribution plan box and content */}
							<Box display="flex" flexDirection="column" width="100%">
								<Box
									width="100%"
									borderRadius="borderRadius20"
									borderStyle="solid"
									borderWidth="borderWidth10"
									borderColor="colorBorder"
									id="distributionPlan"
								>
									<Box margin="space60">
										<Heading as="h3" variant="heading30">
                      Call Distribution Plan
										</Heading>
									</Box>
									<Box
										display="flex"
										flexDirection="row"
										columnGap="space60"
										margin="space60"
										alignItems="center"
									>
										<Button variant="secondary" size="icon">
											<FileIcon
												decorative={false}
												title="View distribution plan"
											/>
										</Button>
										<Box width="100%">
											<Input
												type="text"
												placeholder="Enter the URL"
												name="callDistributionPlanURL"
												id="callDistributionPlanURL"
												onChange={handleChange}
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
						{/* Contracts add and table piece */}
						<Box marginY="space60">
							<Box
								display="flex"
								justifyContent="space-between"
								marginY="space60"
								alignItems="center"
							>
								<Heading as="h3" variant="heading30">
                  Contracts
								</Heading>
								<Box>
									<Button variant="primary" onClick={() => addRow()}>
                    Add Contract
									</Button>
								</Box>
							</Box>
							<Table id="contracts">
								<THead>
									<Tr>
										<Th textAlign="center">Language</Th>
										<Th textAlign="center">Invoke Time</Th>
										<Th textAlign="center">Number of Seats</Th>
										<Th textAlign="right">Actions</Th>
									</Tr>
								</THead>
								<TBody>
									{endClient?.contracts
										? endClient.contracts.map(
											(contract: any, index: number) => (
												<Tr key={contract.endClientContractId}>
													<Td>
														<Input
															type="text"
															name="language"
															defaultValue={contract.language}
															onChange={(e) => handleContractChange(index, e)}
														/>
													</Td>
													<Td>
														<Input
															type="text"
															name="invokeTime"
															placeholder="72hrs or 120hrs"
															pattern="\b(72HRS|120HRS)|(72hrs|120hrs)\b"
															defaultValue={contract.invokeTime}
															onChange={(e) => handleContractChange(index, e)}
														/>
													</Td>
													<Td>
														<Input
															type="text"
															name="numberOfSeats"
															defaultValue={contract.numberOfSeats}
															onChange={(e) => handleContractChange(index, e)}
														/>
													</Td>
													<Td textAlign="right">
														<Box>
															<Button
																variant="destructive"
																onClick={() =>
																	removeRow(contract.endClientContractId)
																}
															>
                                  Remove Contract
															</Button>
														</Box>
													</Td>
												</Tr>
											)
										)
										: ""}
								</TBody>
							</Table>
						</Box>
						{/* Button container and buttons */}
						<Box
							display="flex"
							justifyContent="space-between"
							marginY="space60"
						>
							<Button
								variant="secondary"
								onClick={() => navigate("/end-clients")}
							>
                Cancel
							</Button>
							<Button type="submit" variant="primary">
                Save
							</Button>
						</Box>
					</form>
				</Box>
			</Box>
		</Box>
	);
};
